import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Card, Checkbox, Input } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import * as Styles from '../HostedForm.styles';
import { HostedFormStep } from '../../HostedForm.types';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';

type FormFieldValues = {
  website: string;
  withoutWebsite: string | boolean;
  registryName: string;
};

export const Website = () => {
  const { partnerConfig } = usePartnerConfig();

  const { setOrganisation, organisation, handleNext, handleBack } = useValidationStepData();
  const { t } = useTranslation();

  const {
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useFormContext<FormFieldValues>();

  const handleNavigateNext = () => {
    const website = getValues('website');

    if (organisation && website) {
      setOrganisation({ ...organisation, website });
    }

    if (website) {
      setValue('withoutWebsite', '');
    }

    if (partnerConfig?.configuration.avCheckAvailable !== undefined && !partnerConfig?.configuration.avCheckAvailable) {
      const hasPartnerFields = !!partnerConfig?.configuration?.partnerFields?.length;

      if (hasPartnerFields) {
        return handleNext(HostedFormStep.PARTNER_FIELDS);
      }
      return handleNext(HostedFormStep.TERMS);
    } else {
      return handleNext(HostedFormStep.CONTACT_DETAILS);
    }
  };

  const handleNavigateBack = () => {
    setValue('website', '');
    handleBack();
  };

  return (
    <Card
      shouldContainBackButton
      goBack={handleNavigateBack}
      title={t('hostedValidation.title.haveWebsite')}
      subTitle={t('hostedValidation.title.enterWebsite')}
    >
      <Box sx={Styles.FlexContainer}>
        <Controller
          name="website"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Input
              disabled={!!getValues('withoutWebsite')}
              name={name}
              value={value}
              onChange={onChange}
              label={t('hostedValidation.label.website')}
              placeholder={t('hostedValidation.placeholder.website')}
              errorMessage={errors.website?.message}
            />
          )}
        />
        {!getValues('registryName') && (
          <Controller
            name="withoutWebsite"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Checkbox
                checked={!!value}
                name={name}
                label={t('hostedValidation.label.noWebsite')}
                onChange={(event) => {
                  setValue('website', '');
                  if (event.target.checked) {
                    clearErrors('website');
                  }
                  if (organisation) {
                    setOrganisation({ ...organisation, website: '' });
                  }

                  return onChange(event);
                }}
                disabled={false}
              />
            )}
          />
        )}
      </Box>
      <Button
        isDisabled={
          (errors.website || !dirtyFields.website || (!getValues('withoutWebsite') && getValues('website') === '')) &&
          !getValues('withoutWebsite')
        }
        onClick={handleNavigateNext}
      >
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
