import { useContext } from 'react';

import { ProgressContext } from '@percent/hosted-validation-form/context/progress/ProgressContext/ProgressContext';

export const useProgress = () => {
  const context = useContext(ProgressContext);

  if (context === undefined) {
    throw new Error('useProgress must be used within a ProgressContextController');
  }

  return context;
};
