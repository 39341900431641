import { ColorPrimitive, Theme, colorPrimitives, theme } from '../theme/theme'

export const composeTheme = (
  customPalette?: Partial<Record<ColorPrimitive, string>>
): Theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      ...customPalette,
      primary: customPalette?.primary400 || colorPrimitives.primary400,
      primaryHover: customPalette?.primary600 || colorPrimitives.primary600,
      primaryActive: customPalette?.primary600 || colorPrimitives.primary600,
      primaryFocus: customPalette?.primary600 || colorPrimitives.primary600,
      secondary: customPalette?.secondary400 || colorPrimitives.secondary400,
      secondaryHover:
        customPalette?.secondary600 || colorPrimitives.secondary600,
      secondaryActive:
        customPalette?.secondary600 || colorPrimitives.secondary600,
      secondaryFocus:
        customPalette?.secondary600 || colorPrimitives.secondary600,
      neutral: customPalette?.neutral400 || colorPrimitives.neutral400,
      neutralHover: customPalette?.neutral600 || colorPrimitives.neutral600,
      neutralActive: customPalette?.neutral600 || colorPrimitives.neutral600,
      neutralFocus: customPalette?.neutral600 || colorPrimitives.neutral600,
    },
  }
}
