import React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { StyledTextProps, TextProps, TextVariant } from './text.types'
import { styledVariant } from '../../util'
import { Theme } from '../../theme'

const sizes: Record<TextVariant, (obj: { theme: Theme }) => CSSProp> = {
  small: ({ theme }) => css`
    font-size: ${theme.fontSizes.bodySmall};
  `,
  medium: ({ theme }) => css`
    font-size: ${theme.fontSizes.body};
  `,
  large: ({ theme }) => css`
    font-size: ${theme.fontSizes.bodyLarge};
  `,
}

export const StyledText = styled.span<StyledTextProps>`
  ${styledVariant('$size', sizes)};
  ${({ theme, $fontWeight, $color }) =>
    `
    margin: 0;
    color: ${$color || theme.colors.gray900};
    line-height: ${theme.sizes[6]};
    letter-spacing: 0.013rem;
    font-weight: ${theme.fontWeights[$fontWeight]};
    `}
  ${({ $align }) => $align && `text-align: ${$align};`}
  ${({ $overflowEllipsis }) =>
    $overflowEllipsis &&
    `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
    `}
    ${({ $textWrap }) =>
    $textWrap &&
    `
    white-space: normal;
    max-width: 100%;
    hyphens: auto;
    `}
`

export const Text = ({
  size = 'medium',
  fontWeight = 'regular',
  as = 'span',
  align = 'inherit',
  children,
  overflowEllipsis = false,
  textWrap = false,
  color,
  dataTestId,
  className,
}: TextProps) => {
  return (
    <StyledText
      $size={size}
      $fontWeight={fontWeight}
      as={as}
      $align={align}
      $overflowEllipsis={overflowEllipsis}
      $textWrap={textWrap}
      $color={color}
      data-testid={dataTestId}
      className={className}
    >
      {children}
    </StyledText>
  )
}
