import { Button as BaseButton } from '@mui/material';

import { ButtonProps } from './Button.types';
import * as Styles from './Button.styles';

export const Button = ({ isDisabled, onClick, children, component, width }: ButtonProps) => {
  return (
    <BaseButton sx={Styles.Button(width)} disabled={isDisabled} onClick={onClick} component={component ?? 'button'}>
      {children}
    </BaseButton>
  );
};
