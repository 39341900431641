import { useContext } from 'react';

import { ClientContext } from '@percent/hosted-validation-form/context/client/clientContext/ClientContext';

export const useClient = () => {
  const context = useContext(ClientContext);

  if (context === undefined) {
    throw new Error('ClientContext must be within ClientProvider');
  }

  return context;
};
