import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PRIVACY_POLICY_URL } from '@percent/hosted-validation-form/constants/privacyPolicyUrl';
import { TERMS_URL } from '@percent/hosted-validation-form/constants/termsUrl';

import * as Styles from './Card.styles';
import { Logo } from '@percent/lemonade';
import { GOODSTACK_URL } from '@percent/hosted-validation-form/constants/goodstackUrl';

export const CardFooter = () => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Footer}>
      <Box sx={Styles.LogoWrapper}>
        Powered by
        <Link underline="none" target="_blank" href={GOODSTACK_URL} data-testid="footer-logo">
          <Logo />
        </Link>
      </Box>
      <Typography>
        <Link underline="none" target="_blank" href={TERMS_URL} data-testid="footer-terms">
          {t('hostedValidation.footer.terms')}
        </Link>
        {' • '}
        <Link underline="none" target="_blank" href={PRIVACY_POLICY_URL} data-testid="footer-privacy-policy">
          {t('hostedValidation.footer.privacy')}
        </Link>
      </Typography>
    </Box>
  );
};
