import { Typography, Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@percent/hosted-validation-form/ui';
import { ReactComponent as CompleteIcon } from '@percent/hosted-validation-form/assets/icons/complete.svg';
import * as CardStyles from '@percent/hosted-validation-form/ui/Card/Card.styles';
import * as Styles from '../HostedForm.styles';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';

export const Complete = () => {
  const { getValues } = useFormContext();
  const { partnerConfig } = usePartnerConfig();
  const { t } = useTranslation();

  useEffect(() => {
    if (partnerConfig?.redirectUrl) {
      setTimeout(() => {
        window.location.href = partnerConfig.redirectUrl;
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card shouldContainBackButton={false}>
      <Box sx={Styles.CompleteIcon}>
        <CompleteIcon />
      </Box>
      <Typography sx={CardStyles.Title(true)}>{t('hostedValidation.complete.thanksForApplication')}</Typography>
      <Typography sx={CardStyles.SubTitle}>
        {partnerConfig?.configuration?.avCheckAvailable !== undefined && !partnerConfig?.configuration?.avCheckAvailable
          ? t('hostedValidation.complete.partneredWithPercent', { percent: 'Goodstack' })
          : t('hostedValidation.complete.partnerWithPercent', { percent: 'Goodstack', email: getValues('email') })}
        <br />
        {t('hostedValidation.complete.willLetYouKnow')}
      </Typography>
    </Card>
  );
};
