import { useContext } from 'react';

import { PartnerConfigContext } from '@percent/hosted-validation-form/context/partnerConfig/PartnerConfigContext/PartnerConfigContext';

export const usePartnerConfig = () => {
  const context = useContext(PartnerConfigContext);

  if (context === undefined) {
    throw new Error('usePartnerConfig must be used within a PartnerConfigContextController');
  }

  return context;
};
