import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const ResultsCount: Styles = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
  color: (theme) => theme.palette.grey['160'],
};

export const SearchInput: Styles = {
  '&&& #search-clear': {
    opacity: '1 !important',
    pointerEvents: 'auto !important',
  },

  '&&& input[type="search" i]::-webkit-search-cancel-button': {
    opacity: '1 !important',
    pointerEvents: 'auto !important',
  },
};

export const SearchIcon: Styles = {
  '&': {
    zIndex: 1,
  },

  '&&& svg > path': {
    fill: (theme) => theme.palette.grey['100'],
  },
};
