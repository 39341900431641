import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const Wrapper = (hasValue: boolean): Styles => ({
  marginBottom: '16px',

  '.MuiSelect-select': {
    color: ({ palette }) => (hasValue ? palette.grey['200'] : palette.grey['160']),
    fontSize: '14px',
  },

  '&&& .MuiOutlinedInput-notchedOutline': {
    borderColor: ({ palette }) => palette.grey['020'],
  },

  '&:hover': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: ({ palette }) => `${palette.primary.dark} !important`,
    },
  },

  '.Mui-focused > .MuiOutlinedInput-notchedOutline': {
    borderColor: ({ palette }) => `${palette.primary.main} !important`,
  },
});

export const Select: Styles = {
  width: '100%',
};

export const Menu: Styles = {
  marginTop: '8px',

  '.MuiList-root': {
    paddingTop: '8px',
  },

  '.MuiPaper-root': {
    maxWidth: {
      xs: 'calc(100vw - 64px)',
      md: '560px',
    },
    height: 'auto',
    maxHeight: {
      xs: '200px',
      sm: '300px',
    },
    filter: 'drop-shadow(0px 24px 40px rgba(7, 12, 24, 0.08))',
    boxShadow: 'none',
  },

  '.MuiMenuItem-root': {
    whiteSpace: 'break-spaces',
    fontSize: '14px',
    height: 'auto',
    minHeight: '48px',

    '&:hover': {
      boxShadow: 'inset 2px 0px 0 0px #6A6D74',
      border: 'none',
    },
  },
};

export const Label: Styles = {
  position: 'static',
  transform: 'none',
  marginBottom: '4px',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
  color: (theme) => `${theme.palette.grey['200']} !important`,
  whiteSpace: 'break-spaces',
};
