import { linearProgressClasses } from '@mui/material/LinearProgress';

import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const ProgressBar: Styles = {
  width: '176px',
  height: 4,
  borderRadius: 999,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: (theme) => theme.palette.grey['020'],
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: (theme) => theme.palette.clientColor,
  },
};
