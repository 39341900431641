import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Card, Input } from '@percent/hosted-validation-form/ui';
import { HostedFormStep } from '../../HostedForm.types';
import * as Styles from '../HostedForm.styles';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';

type FormFieldValues = {
  addressLine1: string;
  addressLine2: string;
  state: string;
  city: string;
  postal: string;
};

export const OrganisationAddress = () => {
  const { handleNext, handleBack } = useValidationStepData();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<FormFieldValues>();
  const { t } = useTranslation();

  const handleNavigateNext = () => handleNext(HostedFormStep.WEBSITE);

  const handleNavigateBack = () => {
    setValue('addressLine1', '');
    setValue('addressLine2', '');
    setValue('state', '');
    setValue('city', '');
    setValue('postal', '');
    handleBack();
  };

  return (
    <Card shouldContainBackButton goBack={handleNavigateBack} title={t('hostedValidation.title.physicalAddress')}>
      <Box sx={Styles.OrganisationAddressContainer}>
        <Controller
          name="addressLine1"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Input
              value={value}
              name={name}
              label={t('hostedValidation.label.address')}
              placeholder={t('hostedValidation.placeholder.address')}
              onChange={onChange}
              errorMessage={errors.addressLine1?.message}
            />
          )}
        />
        <Controller
          name="addressLine2"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Input
              value={value}
              name={name}
              label={t('hostedValidation.label.unit')}
              placeholder={t('hostedValidation.placeholder.unit')}
              onChange={onChange}
              errorMessage={errors.addressLine2?.message}
            />
          )}
        />
        <Controller
          name="state"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Input
              value={value}
              name={name}
              label={t('hostedValidation.label.state')}
              placeholder={t('hostedValidation.placeholder.state')}
              onChange={onChange}
              errorMessage={errors.state?.message}
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Input
              value={value}
              name={name}
              label={t('hostedValidation.label.city')}
              placeholder={t('hostedValidation.placeholder.city')}
              onChange={onChange}
              errorMessage={errors.city?.message}
            />
          )}
        />
        <Controller
          name="postal"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Input
              value={value}
              name={name}
              label={t('hostedValidation.label.postal')}
              placeholder={t('hostedValidation.placeholder.postal')}
              onChange={onChange}
              errorMessage={errors.postal?.message}
            />
          )}
        />
      </Box>
      <Button isDisabled={Object.keys(errors).length > 0} onClick={handleNavigateNext}>
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
