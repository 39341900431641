import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const Card: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '560px',
  width: '100%',
  minWidth: {
    xs: '100%',
    md: 'unset',
  },
  minHeight: {
    xs: 'calc(100vh - 62px)',
    md: 'calc(100vh - 94px)',
  },
  height: 'auto',
  boxShadow: '0px 40px 72px rgba(7, 12, 24, 0.08)',
  borderRadius: '8px 8px 0px 0px',
  paddingX: {
    xs: '16px',
    md: '96px',
  },
  position: 'relative',
  backgroundColor: (theme) => theme.palette.grey['000'],
};

export const Logo: Styles = {
  border: (theme) => `3px solid ${theme.palette.grey['010']}`,
  borderRadius: '10px',
  marginTop: '-37px',
  marginBottom: {
    xs: '16px',
    md: '28px',
  },
  width: {
    xs: '74px',
    md: '92px',
  },
  height: {
    xs: '74px',
    md: '92px',
  },
};

export const Title = (withSubTitle: boolean): Styles => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: {
    xs: '22px',
    md: '24px',
  },
  lineHeight: {
    xs: '28px',
    md: '32px',
  },
  textAlign: 'center',
  color: (theme) => theme.palette.grey['200'],
  margin: {
    xs: withSubTitle ? '24px 0 16px 0' : '24px 0 32px 0',
    md: withSubTitle ? '32px 0 16px 0' : '32px 0 40px 0',
  },
});

export const SubTitle: Styles = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  letterSpacing: '0.2px',
  color: (theme) => theme.palette.grey['160'],
  marginBottom: {
    xs: '32px',
    md: '40px',
  },
};

export const Children: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  width: '100%',
  position: 'relative',
};

export const Divider: Styles = {
  height: '1px',
  width: '100%',
  backgroundColor: (theme) => theme.palette.grey['020'],
  marginTop: {
    xs: '16px',
    md: '36px',
  },
};

export const Footer: Styles = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',

  '& p': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    color: (theme) => theme.palette.grey['100'],
    margin: '24px 0 24px 5px',
  },

  '& a': {
    color: (theme) => theme.palette.grey['100'],
  },

  svg: {
    verticalAlign: 'text-top',
  },
};

export const BackButton: Styles = {
  position: 'absolute',
  top: {
    xs: '10px',
    md: '18px',
  },
  left: {
    xs: '10px',
    md: '18px',
  },
};

export const LogoWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  fontSize: '10px',
  fontWeight: 500,
  color: (theme) => theme.palette.text.secondary,

  svg: {
    width: 91,
  },
};
