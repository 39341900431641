import { Select as BaseSelect, FormControl, InputLabel } from '@mui/material';

import { SelectProps } from './Select.types';
import * as Styles from './Select.styles';
import { renderMenuItem } from './MenuItem/MenuItem';

export const Select = ({ children, placeholder, value, onChange, label, isDisabled = false, id }: SelectProps) => {
  const renderValueProp = {
    ...(!value && { renderValue: (value: string) => (value !== '' ? value : placeholder) }),
  };
  const labelId = id && `${id}-label`;
  return (
    <FormControl sx={Styles.Wrapper(!!value)} fullWidth>
      {label && (
        <InputLabel id={labelId} sx={Styles.Label}>
          {label}
        </InputLabel>
      )}
      <BaseSelect
        labelId={labelId}
        disabled={isDisabled}
        sx={Styles.Select}
        value={value}
        onChange={onChange}
        displayEmpty
        MenuProps={{
          sx: Styles.Menu,
        }}
        {...renderValueProp}
      >
        {children}
      </BaseSelect>
    </FormControl>
  );
};

Select.renderMenuItem = renderMenuItem;
