import kebabCase from 'lodash/kebabCase'
import isPlainObject from 'lodash/isPlainObject'

const flattenTheme = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: Record<string, any>,
  parentKeys: string[] = []
): Record<string, string> => {
  const flatTheme = Object.keys(input).reduce((previousValue, currentKey) => {
    const valueForKey = input[currentKey]
    const flattenCurrentValue = isPlainObject(valueForKey)

    const parentKeysWithCurrentKey = [...parentKeys, currentKey]

    if (flattenCurrentValue) {
      const flattenedValue = flattenTheme(valueForKey, parentKeysWithCurrentKey)
      return { ...previousValue, ...flattenedValue }
    }

    const keysToString = parentKeysWithCurrentKey.join('-')

    return {
      ...previousValue,
      [keysToString]: valueForKey,
    }
  }, {})

  return flatTheme
}

const generateCssVariableEntry = (key: string, value: string | number) => {
  const indentation = '  '
  const prefix = '--'
  const keyToKebab = kebabCase(key)
  return `${indentation}${prefix}${keyToKebab}: ${value};`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateCssVariablesForTheme = (theme: Record<string, any>) => {
  const flattenedTheme = flattenTheme(theme)
  const keys = Object.keys(flattenedTheme)

  const variables = keys.map((key) =>
    generateCssVariableEntry(key, flattenedTheme[key])
  )

  const variablesAsString = variables.join('\n')
  return variablesAsString
}
