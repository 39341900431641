import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { ToastProvider } from '../toast/toast-provider'
import { LemonadeProviderProps } from './lemonade-provider.types'
import { composeTheme } from '../../util'
import { ThemedGlobalStyle } from '../../theme/global'

export const LemonadeProvider: React.FC<LemonadeProviderProps> = ({
  children,
  customPalette,
}) => {
  const lemonadeTheme = useMemo(
    () => composeTheme(customPalette),
    [customPalette]
  )

  return (
    <ThemeProvider theme={lemonadeTheme}>
      <ThemedGlobalStyle theme={lemonadeTheme} />
      <ToastProvider>{children}</ToastProvider>
    </ThemeProvider>
  )
}
