import { deDE, enUS, esES, frFR, ptBR } from '@mui/material/locale';

export const namespaces = {
  translations: 'translations',
};

export const languages = {
  de: 'de',
  fr: 'fr',
  es: 'es',
  en: 'en',
  pt_BR: 'pt-BR',
};

export const defaultLang = languages.en;

export const languagesToMaterialUiLocalesMap = {
  [languages.de]: deDE,
  [languages.fr]: frFR,
  [languages.es]: esES,
  [languages.en]: enUS,
  [languages.pt_BR]: ptBR,
};
