import { LinearProgress } from '@mui/material';

import { useProgress } from '@percent/hosted-validation-form/hooks/useProgress/useProgress';

import * as Styles from './ProgressBar.styles';

export const ProgressBar = () => {
  const { progress } = useProgress();
  return <LinearProgress variant="determinate" sx={Styles.ProgressBar} value={progress} />;
};
