const checkSessionStorageIsAvailable = () => {
  try {
    const testKey = '__random_key_we_are_not_going_to_use__';
    sessionStorage.setItem(testKey, testKey);
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const storage = {
  setItem: (name: string, value: string): void => {
    if (!checkSessionStorageIsAvailable()) return;

    return sessionStorage.setItem(name, value);
  },
  getItem: (name: string): string | null | undefined => {
    if (!checkSessionStorageIsAvailable()) return;

    return sessionStorage.getItem(name);
  },
};

export const sessionStorageIsAvailable = checkSessionStorageIsAvailable();
