import tinycolor from 'tinycolor2';

import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const Button = (width?: string): Styles => ({
  width: width || '178px',
  height: '48px',
  backgroundColor: (theme) => theme.palette.clientColor,
  borderRadius: '8px',
  color: (theme) =>
    tinycolor(theme.palette.clientColor).isDark() ? theme.palette.grey['000'] : theme.palette.grey['200'],
  fontSize: '13px',
  fontWeight: '600',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: ({ palette: { clientColor } }) =>
      tinycolor(clientColor).isDark()
        ? tinycolor(clientColor).brighten(15).toString()
        : tinycolor(clientColor).darken(15).toString(),
    color: (theme) =>
      tinycolor(theme.palette.clientColor).isDark() ? theme.palette.grey['000'] : theme.palette.grey['200'],
  },

  '&:disabled': {
    backgroundColor: (theme) => theme.palette.grey['010'],
    color: (theme) => theme.palette.grey['100'],
  },
});
