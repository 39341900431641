import { useForm, FormProvider } from 'react-hook-form';
import { object, string, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';
import { ValidationSubmissionArguments } from '@percent/hosted-validation-form/api/actions/validationSubmissionAction';
import { isValidWebsiteURL } from '@percent/hosted-validation-form/app/HostedForm/HostedForm/websiteValidator/websiteValidator';
import { partnerFieldValidator } from '@percent/hosted-validation-form/app/HostedForm/HostedForm/partnerFieldValidator/partnerFieldValidator';

import { FormContextControllerProps } from './FormContextController.types';

export const FormContextController = ({ children, defaultFieldValues }: FormContextControllerProps) => {
  const { partnerConfig } = usePartnerConfig();
  const { t } = useTranslation();
  const CANT_FIND_REGISTRY = t('hostedValidation.typography.cantFindRegistryName');

  const hostedFormSchema = object({
    organisationName: string()
      .required(
        t('hostedValidation.validation.required', {
          fieldName: t('hostedValidation.label.organizationName'),
          interpolation: { escapeValue: false },
        }),
      )
      .max(
        255,
        t('hostedValidation.validation.maxLength', {
          fieldName: t('hostedValidation.label.organizationName'),
          maxCharacterLimit: 255,
          interpolation: { escapeValue: false },
        }),
      ),
    organisationId: string().required(),
    validationInviteId: string().required(),
    firstName: string().when({
      is:
        partnerConfig?.configuration.avCheckAvailable !== undefined
          ? partnerConfig?.configuration.avCheckAvailable
          : true,
      then: string()
        .required(
          t('hostedValidation.validation.required', {
            fieldName: t('hostedValidation.label.firstName'),
            interpolation: { escapeValue: false },
          }),
        )
        .max(
          255,
          t('hostedValidation.validation.maxLength', {
            fieldName: t('hostedValidation.label.firstName'),
            maxCharacterLimit: 255,
          }),
        ),
    }),
    lastName: string().when({
      is:
        partnerConfig?.configuration.avCheckAvailable !== undefined
          ? partnerConfig?.configuration.avCheckAvailable
          : true,
      then: string()
        .required(
          t('hostedValidation.validation.required', {
            fieldName: t('hostedValidation.label.lastName'),
            interpolation: { escapeValue: false },
          }),
        )
        .max(
          255,
          t('hostedValidation.validation.maxLength', {
            fieldName: t('hostedValidation.label.lastName'),
            maxCharacterLimit: 255,
            interpolation: { escapeValue: false },
          }),
        ),
    }),
    email: string()
      .required(
        t('hostedValidation.validation.required', {
          fieldName: t('hostedValidation.label.email'),
          interpolation: { escapeValue: false },
        }),
      )
      .max(
        255,
        t('hostedValidation.validation.maxLength', {
          fieldName: t('hostedValidation.label.email'),
          maxCharacterLimit: 255,
          interpolation: { escapeValue: false },
        }),
      ),
    website: isValidWebsiteURL(t('hostedValidation.validation.websiteValid'))
      .when('registryName', {
        is: (value: string) => !!value,
        then: string()
          .required(
            t('hostedValidation.validation.required', {
              fieldName: t('hostedValidation.label.website'),
              interpolation: { escapeValue: false },
            }),
          )
          .max(
            255,
            t('hostedValidation.validation.maxLength', {
              fieldName: t('hostedValidation.label.website'),
              maxCharacterLimit: 255,
              interpolation: { escapeValue: false },
            }),
          ),
      })
      .max(
        255,
        t('hostedValidation.validation.maxLength', {
          fieldName: t('hostedValidation.label.website'),
          maxCharacterLimit: 255,
          interpolation: { escapeValue: false },
        }),
      ),
    language: string().required(),
    registryName: string().when('organisationId', {
      is: '',
      then: string()
        .required(
          t('hostedValidation.validation.required', {
            fieldName: t('hostedValidation.label.registryName'),
            interpolation: { escapeValue: false },
          }),
        )
        .max(
          255,
          t('hostedValidation.validation.maxLength', {
            fieldName: t('hostedValidation.label.registryName'),
            maxCharacterLimit: 255,
            interpolation: { escapeValue: false },
          }),
        ),
    }),
    registryId: string().when('organisationId', {
      is: '',
      then: string()
        .required(
          t('hostedValidation.validation.required', {
            fieldName: t('hostedValidation.label.registryId'),
            interpolation: { escapeValue: false },
          }),
        )
        .max(
          255,
          t('hostedValidation.validation.maxLength', {
            fieldName: t('hostedValidation.label.registryId'),
            maxCharacterLimit: 255,
            interpolation: { escapeValue: false },
          }),
        ),
    }),
    withoutWebsite: boolean(),
    differentEmail: boolean(),
    addressLine1: string().max(
      255,
      t('hostedValidation.validation.maxLength', {
        fieldName: t('hostedValidation.label.address'),
        maxCharacterLimit: 255,
        interpolation: { escapeValue: false },
      }),
    ),
    addressLine2: string().max(
      255,
      t('hostedValidation.validation.maxLength', {
        fieldName: t('hostedValidation.label.unit'),
        maxCharacterLimit: 255,
        interpolation: { escapeValue: false },
      }),
    ),
    city: string().max(
      255,
      t('hostedValidation.validation.maxLength', {
        fieldName: t('hostedValidation.label.city'),
        maxCharacterLimit: 255,
        interpolation: { escapeValue: false },
      }),
    ),
    postal: string().max(
      255,
      t('hostedValidation.validation.maxLength', {
        fieldName: t('hostedValidation.label.postal'),
        maxCharacterLimit: 255,
        interpolation: { escapeValue: false },
      }),
    ),
    state: string().max(
      255,
      t('hostedValidation.validation.maxLength', {
        fieldName: t('hostedValidation.label.state'),
        maxCharacterLimit: 255,
        interpolation: { escapeValue: false },
      }),
    ),
    unknownRegistryName: string()
      .when('registryName', {
        is: CANT_FIND_REGISTRY,
        then: string()
          .required(
            t('hostedValidation.validation.required', {
              fieldName: t('hostedValidation.label.registryName'),
              interpolation: { escapeValue: false },
            }),
          )
          .max(
            255,
            t('hostedValidation.validation.maxLength', {
              fieldName: t('hostedValidation.label.registryName'),
              maxCharacterLimit: 255,
              interpolation: { escapeValue: false },
            }),
          ),
      })
      .max(
        255,
        t('hostedValidation.validation.maxLength', {
          fieldName: t('hostedValidation.label.registryName'),
          maxCharacterLimit: 255,
          interpolation: { escapeValue: false },
        }),
      ),
  });

  let schema = hostedFormSchema;

  partnerConfig?.configuration?.partnerFields?.map((field) => {
    schema = schema.concat(
      object({
        partnerFields: object({
          [field.id]: partnerFieldValidator(field, t),
        }),
      }),
    );
  });

  const defaultValues = {
    organisationId: '',
    organisationName: '',
    validationInviteId: '',
    firstName: '',
    lastName: '',
    email: '',
    language: '',
    website: '',
    registryName: '',
    unknownRegistryName: '',
    file: '',
    ...defaultFieldValues,
  };

  const methods = useForm<ValidationSubmissionArguments>({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'all',
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
