export const environments = {
  LOCAL: 'local',
  STAGING: 'staging',
  PREPROD: 'preprod',
  SANDBOX: 'sandbox',
  PRODUCTION: 'production',
  QA: 'qa',
};

export type environmentType = {
  local: 'local';
  staging: 'staging';
  preprod: 'preprod';
  sandbox: 'sandbox';
  production: 'production';
  qa: 'qa';
};
