import { createGlobalStyle } from 'styled-components'
import { normalize } from './normalize'
import { fontFaces, PPRightGroteskFaces, fontFamilyName } from './fonts'
import { generateCssVariablesForTheme } from '../scripts/generate-css-variables'
import { Theme } from './theme'

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  
  ${fontFaces};
  ${PPRightGroteskFaces};

  :root {
    ${(props) => generateCssVariablesForTheme(props.theme)}
  }

  html, body, form, fieldset, table, tr, td, img {
    margin: 0;
    padding: 0;
    font-family: '${fontFamilyName}', sans-serif;
  }

  input, button, select, textarea, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
  }

  ul, li {
    outline: none;
    & :focus {
      outline: none;
    }
  }

  body {
    font-size: 16px;
  }

  #gallery-slider {
    display: none !important;
  }

  #gallery-thumbnails {
    .image-gallery-thumbnail {
      border: none !important;
      margin: 0 24px 24px 0;
    }

    .image-gallery-thumbnails-container {
      display: flex;
      flex-wrap: wrap;
    }

    .image-gallery-thumbnail.active {
      border-color: ${(props) => (props.theme as Theme).colors.primary400};
      border-radius: 8px;
    }

    .image-gallery-thumbnail:hover {
      border: 4px solid ${(props) => (props.theme as Theme).colors.primary400};
      border-radius: 8px;
      background: rgba(33, 37, 53, 0.16);
    }
  }

  #gallery-image {
    max-height: calc(100vh - 120px);
  }

  .image-gallery-svg {
    color:  ${(props) => (props.theme as Theme).colors.primary400};
  }

  .image-gallery-thumbnail.active {
    border-color: ${(props) => (props.theme as Theme).colors.primary400};
  }

  .image-gallery-thumbnail:hover {
    border: 4px solid  ${(props) => (props.theme as Theme).colors.primary400};
  }
`

export const ThemedGlobalStyle = ({ theme }: { theme: Theme }) => {
  return <GlobalStyle theme={theme} />
}
