import { AxiosError } from 'axios';

import { ClientResponse } from '@percent/hosted-validation-form/api/types/types';
import { MutationFn } from '@percent/hosted-validation-form/hooks/useMutation/useMutation.types';

export type ValidationSubmissionArguments = {
  validationInviteId?: string;
  organisationId?: string;
  organisationName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  language?: string;
  website?: string;
  registryName?: string;
  unknownRegistryName?: string;
  file?: string;
};

export type ValidationSubmissionResponse = {
  error: {
    code: string;
    title: string;
    message: string;
  };
};

export const validationSubmissionAction: MutationFn<
  ValidationSubmissionArguments,
  ClientResponse<ValidationSubmissionResponse>,
  AxiosError
> = (body) => {
  return {
    endpoint: '/validation-submissions',
    method: 'POST',
    params: body,
  };
};
