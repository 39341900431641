import { Step, StepLabel, Stepper as BaseStepper, Typography, Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as CheckedMark } from '@percent/hosted-validation-form/assets/icons/checked-mark.svg';
import { PRIVACY_POLICY_URL } from '@percent/hosted-validation-form/constants/privacyPolicyUrl';
import { TERMS_URL } from '@percent/hosted-validation-form/constants/termsUrl';
import { ReactComponent as Expand } from '@percent/hosted-validation-form/assets/icons/expand.svg';

import * as Styles from './Stepper.styles';

export const Stepper = () => {
  const { t } = useTranslation();

  return (
    <BaseStepper sx={Styles.Stepper} activeStep={2} orientation="vertical">
      <Step>
        <StepLabel sx={Styles.Label} StepIconComponent={CheckedMark}>
          <Typography sx={Styles.Text} variant="h6">
            {t('hostedValidation.stepper.representative')}
          </Typography>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel sx={Styles.Label} StepIconComponent={CheckedMark}>
          <Typography sx={Styles.Text} variant="h6">
            <Trans t={t} i18nKey="hostedValidation.stepper.agreeTerms">
              You've read Goodstack's{' '}
              <Link sx={Styles.Link} target="_blank" href={TERMS_URL} rel="noopener noreferrer">
                terms & conditions <Expand />
              </Link>
              and{' '}
              <Link sx={Styles.Link} target="_blank" href={PRIVACY_POLICY_URL} rel="noopener noreferrer">
                privacy policy <Expand />
              </Link>
            </Trans>
          </Typography>
        </StepLabel>
      </Step>
    </BaseStepper>
  );
};
