import { ClientResponse } from '@percent/hosted-validation-form/api/types/types';
import { GetOrganisationsConfiguration } from '@percent/hosted-validation-form/app/HostedForm/HostedForm/HostedForm.types';
import { InfiniteQueryFn } from '@percent/hosted-validation-form/hooks/useInfiniteQuery/useInfiniteQuery.types';

export const fetchInfiniteOrganisationsQuery: InfiniteQueryFn<
  { pageSize: number; query: string; countryCode: string },
  ClientResponse<GetOrganisationsConfiguration>
> = (args) => {
  return {
    endpoint: `/organisations`,
    args,
  };
};
