import { useMemo, useState } from 'react';

import { Organisation } from '../../HostedForm.types';
import { ValidationStepContext } from '../ValidationStepContext';
import { useNavigationStack } from '@percent/hosted-validation-form/hooks/useNavigationStack/useNavigationStack';

import { ValidationStepContextControllerProps } from './ValidationStepContextController.types';

export function ValidationStepContextController({
  defaultOrganisation,
  children,
  defaultCountry = '',
}: ValidationStepContextControllerProps) {
  const [organisation, setOrganisation] = useState<Organisation | undefined>(defaultOrganisation);
  const [country, setCountry] = useState<string>(defaultCountry);
  const { handleNext, handleBack, validationStep } = useNavigationStack();

  const validationStepContextValue = useMemo(
    () => ({
      validationStep,
      organisation,
      setOrganisation,
      country,
      setCountry,
      handleNext,
      handleBack,
    }),
    [validationStep, organisation, setOrganisation, country, setCountry, handleNext, handleBack],
  );

  return <ValidationStepContext.Provider value={validationStepContextValue}>{children}</ValidationStepContext.Provider>;
}
