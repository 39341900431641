function getTextWidth(text: string, name: string) {
  const canvas = document.createElement('canvas');

  const context: (CanvasRenderingContext2D & { letterSpacing?: string }) | null = canvas.getContext('2d');
  const el = document.getElementById(name) as Element;

  if (context && document && window && el) {
    context.letterSpacing = '0.2px';
    context.font = getComputedStyle(el).font;

    return context.measureText(text).width;
  }

  return el?.scrollWidth;
}

export function isEllipsisActive(placeholder: string, name: string) {
  const el = document.getElementById(name) as HTMLElement;
  const width = getTextWidth(placeholder, name);
  const inputInlinePadding = 32;

  if (width > el?.offsetWidth - inputInlinePadding) {
    return true;
  }

  return false;
}
