import React from 'react'
import styled from 'styled-components'
import { IconProps, StyledIconProps } from './icon.types'
import { IconRegistry } from './icon-registry'

export const StyledIcon = styled.svg<StyledIconProps>`
  ${({ theme, $color, $size }) =>
    `
    width: ${theme.sizes[$size]};
    height: ${theme.sizes[$size]};

    & path {
      fill: ${$color === 'inherit' ? 'currentColor' : theme.colors[$color]};
    }
  `}
`

export const Icon = ({ name, color, size = 12, customTestId }: IconProps) => (
  <StyledIcon
    as={
      // eslint-disable-next-line
      IconRegistry[name] as any
    }
    $color={color}
    $size={size}
    role="img"
    data-testid={customTestId || `${name}Icon`}
  />
)
