import {
  OutlinedInput,
  FormControl,
  InputLabel,
  InputAdornment,
  Typography,
  useMediaQuery,
  Tooltip,
} from '@mui/material';

import { theme } from '@percent/hosted-validation-form/theme/theme';
import { isEllipsisActive } from '@percent/hosted-validation-form/utils/isEllipsisActive';

import { InputProps } from './Input.types';
import * as Styles from './Input.styles';

export const Input = ({
  value,
  name,
  onChange,
  label,
  placeholder,
  errorMessage,
  disabled,
  endAdornment,
  type,
}: InputProps) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <FormControl sx={Styles.Input(!!disabled, !!errorMessage, !!value)} disabled={disabled}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Tooltip title={isEllipsisActive(placeholder, name) && !value ? placeholder : ''} placement="top-start">
        <OutlinedInput
          sx={!!endAdornment && matches ? Styles.EmailInput : undefined}
          error={!!errorMessage}
          id={name}
          name={name}
          placeholder={placeholder}
          label={label}
          value={value}
          onChange={onChange}
          type={type}
          endAdornment={
            endAdornment && (
              <InputAdornment sx={Styles.Adornment} position="end">
                <Typography>@{endAdornment}</Typography>
              </InputAdornment>
            )
          }
        />
      </Tooltip>
      {errorMessage && <Typography sx={Styles.ErrorMessage}>{errorMessage}</Typography>}
    </FormControl>
  );
};
