export class ErrorBase extends Error {
  override message: string;
  override cause?: Error;

  constructor({ message, cause }: { message: string; cause?: Error }) {
    super();

    this.message = message;
    this.cause = cause;
  }
}
