import { createTheme } from '@mui/material';

const palette = {
  primary: {
    main: '#2A31F7',
    light: '#EAEAFE',
    dark: '#232ACA',
  },
  secondary: {
    // It is not used anywhere, just in case
    main: '#FFD152',
    light: '#FFC31F',
    dark: '#FFFAEE',
  },
  grey: {
    '000': '#FFFFFF',
    '010': '#F0F0F1',
    '020': '#E1E2E3',
    '040': '#C3C5C7',
    '060': '#A6A7AC',
    '080': '#888A90',
    '100': '#6A6D74',
    '120': '#4D4F54',
    '140': '#42464F',
    '160': '#2F333D',
    '180': '#1B1F2A',
    '200': '#070C18',
  },
  info: {
    main: '#6798F8',
    light: '#ECF2FE',
  },
  warning: {
    main: '#E86825',
    light: '#FFF1E0',
  },
  success: {
    main: '#01BD54',
    light: '#E6F8EE',
  },
  error: {
    // In designs it is called ALERT
    main: '#E9554E',
    light: '#FCEBEA',
  },
  clientColor: '#FFFFFF', // Initially white, replaced when fetched from server
};

const typography = {
  fontFamily: 'Inter, sans-serif',
};

const components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Inter, sans-serif';
      }
    `,
  },
};

export const theme = createTheme({ palette, typography, components });
