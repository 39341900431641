import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom-v6';
import { Box } from '@mui/material';

import * as Styles from './Layout.styles';

type LayoutProps = {
  children?: ReactNode;
};
export const Layout = ({ children }: LayoutProps) => {
  return (
    <Box sx={Styles.Wrapper}>
      <Outlet />
      {children}
    </Box>
  );
};
