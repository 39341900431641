import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const UploadContainer: Styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: (theme) => theme.palette.grey['010'],
  borderRadius: '4px',
  padding: '8px 16px',

  '& p': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: (theme) => theme.palette.grey['200'],
  },

  input: {
    display: 'none',
  },

  span: {
    height: '40px',
    width: '144px',
  },
};

export const Label: Styles = {
  marginBottom: '4px',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
  color: (theme) => theme.palette.grey['200'],
};

export const HelperText: Styles = {
  marginTop: '8px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '0.5px',
  color: (theme) => theme.palette.grey['100'],
};

export const ErrorMessage: Styles = {
  marginTop: '8px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '0.5px',
  color: (theme) => theme.palette.error.main,
};

export const FileName: Styles = {
  width: 'calc(100% - 160px)',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};
