import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const Wrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  padding: {
    xs: '62px 0px 0px',
    md: '94px 0px 0px',
  },
  minWidth: '100vw',
  height: '100%',
  width: '100%',
  background: (theme) =>
    `linear-gradient(to bottom, ${theme.palette.clientColor} 284px, ${theme.palette.grey['000']} -284px)`,
};
