import { AxiosError, AxiosResponse } from 'axios';

import { AuthError, APIError, BadRequestError, ValidationError } from '@percent/hosted-validation-form/errors';

export const responseSuccessInterceptor = (response: AxiosResponse) => response;

export const responseFailureInterceptor = async (error: AxiosError<{ error: { reasons?: string[] } }>) => {
  try {
    switch (error.response?.status) {
      case 500:
        throw new APIError({ message: error.message, cause: error });

      case 401:
        throw new AuthError({ message: error.message, cause: error });

      case 400:
        throw error.response?.data?.error?.reasons
          ? new ValidationError({ message: error.message, cause: error })
          : new BadRequestError({ message: error.message, cause: error });

      default:
        throw error;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
