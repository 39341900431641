import { Box, CircularProgress } from '@mui/material';

import * as Styles from './Loader.styles';

export const Loader = () => {
  return (
    <Box sx={Styles.LoaderContainer}>
      <CircularProgress color="inherit" />
      <CircularProgress color="inherit" />
    </Box>
  );
};
