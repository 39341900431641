import { Box, Link, List } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import { Button, Card, Error, Loader, SearchInput, SearchResult } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import { HostedFormStep, Organisation } from '../../HostedForm.types';
import * as Styles from '../HostedForm.styles';
import { useHostedValidationsFormAnalytics } from '@percent/hosted-validation-form/hooks/useHostedValidationsFormAnalytics/useHostedValidationsFormAnalytics';

import { SelectOrganisationProps } from './SelectOrganisation.types';
import { ErrorCard } from '@percent/hosted-validation-form/ui/Card/ErrorCard';

export const SelectOrganisation = ({
  data,
  isFetched,
  isFetchedAfterMount,
  isRefetching,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  setSearchQuery,
  isError,
  country,
}: SelectOrganisationProps) => {
  const [organisationQuery, setOrganisationQuery] = useState('');
  const { setOrganisation, handleNext, handleBack } = useValidationStepData();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const { track } = useHostedValidationsFormAnalytics();

  const fetchNextOrganisations = () => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const handleSearch = (value: string) => {
    setOrganisationQuery(value);
    handleDebouncedSearch(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 500),
    [],
  );

  const handleSelectOrganisation = (organisation: Organisation, positionOfResult: number) => {
    setOrganisation(organisation);
    setValue('organisationId', organisation.id);

    track('Organisation Result Selected', {
      searchTerm: organisationQuery,
      searchResultsCount: Number(data?.pages[0].data.totalResults) || 0,
      organisationId: organisation.id,
      positionOfResult,
      country,
    });

    handleNext(HostedFormStep.ORGANISATION_RESULT);
  };

  useEffect(() => {
    if (isError) {
      track('Viewed SelectOrganisation Error', {
        errorLabel: Error.variants.INTERNAL,
        country,
      });
    }
  }, [isError]);

  if (isError) {
    return (
      <ErrorCard
        title={t('hostedValidation.errorMessage.somethingWentWrong')}
        subtitle={t('hostedValidation.errorMessage.reloadPage')}
        variant={Error.variants.INTERNAL}
      />
    );
  }

  const redirectToRegisterOrganisation = () => {
    handleNext(HostedFormStep.REGISTER_ORGANISATION);
  };

  const showAddNonProfitComponent = () => {
    return (
      <Error variant={Error.variants.NOT_FOUND} title={t('hostedValidation.typography.cantFindNonprofit')}>
        <Button
          width="auto"
          isDisabled={false}
          onClick={() => {
            track('Element Clicked', {
              action: 'button',
              label: 'Add your nonprofit',
              lastSearchQuery: organisationQuery,
              country,
            });
            redirectToRegisterOrganisation();
          }}
        >
          + {t('hostedValidation.button.addNonprofit')}
        </Button>{' '}
      </Error>
    );
  };

  const noSearchResults = () => {
    return data?.pages[data.pages.length - 1].data.data.length === 0;
  };

  return (
    <Card shouldContainBackButton goBack={handleBack} title={t('hostedValidation.title.fullLegalName')}>
      <>
        <Box sx={Styles.SearchForm}>
          <SearchInput
            value={organisationQuery}
            name="search"
            label=""
            placeholder={t('hostedValidation.placeholder.nonprofitNameOrRegId')}
            resultsCount={Number(data?.pages[0].data.totalResults) || 0}
            isFetched={isFetched}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          />
        </Box>
        {isFetchedAfterMount && !isRefetching && noSearchResults() ? (
          showAddNonProfitComponent()
        ) : (
          <>
            {isRefetching ? (
              <Loader />
            ) : (
              <>
                {isFetchedAfterMount && (
                  <>
                    <Box sx={Styles.SearchResultsContainer} id="search-results">
                      <InfiniteScroll
                        useWindow={false}
                        loadMore={fetchNextOrganisations}
                        hasMore={hasNextPage}
                        loader={<Loader key="loader" />}
                      >
                        <List key="list">
                          {isFetched &&
                            data?.pages
                              .map((page) => page.data.data)
                              .flat()
                              .map((organisation: Organisation, index: number) => (
                                <Fragment key={organisation.id}>
                                  <SearchResult
                                    name={organisation.name}
                                    registryId={organisation.registryId}
                                    address={organisation.address}
                                    onClick={() => handleSelectOrganisation(organisation, index)}
                                  />
                                </Fragment>
                              ))}
                        </List>
                      </InfiniteScroll>
                    </Box>
                    <Box sx={Styles.GradientLayer} />
                  </>
                )}
                <Link
                  sx={Styles.AddNonprofit}
                  component="button"
                  onClick={() => {
                    track('Element Clicked', {
                      action: 'link',
                      label: 'I can’t find my nonprofit',
                      lastSearchQuery: organisationQuery,
                      searchResultsCount: data?.pages[0].data.totalResults,
                      country,
                    });
                    redirectToRegisterOrganisation();
                  }}
                >
                  {t('hostedValidation.button.cantFindNonprofit')}
                </Link>
              </>
            )}
          </>
        )}
      </>
    </Card>
  );
};
