import { Box, Typography } from '@mui/material';

import BlockedCookieError from '@percent/hosted-validation-form/assets/images/blocked-cookies-error.png';
import InternalError from '@percent/hosted-validation-form/assets/images/internal-error.png';
import WrongLinkError from '@percent/hosted-validation-form/assets/images/wrong-link-error.png';
import NotFoundError from '@percent/hosted-validation-form/assets/images/not-found-error.png';

import * as Styles from './Error.styles';
import { ErrorProps, ErrorVariant } from './Error.types';

const images: { [key in ErrorVariant]: string } = {
  [ErrorVariant.INTERNAL]: InternalError,
  [ErrorVariant.WRONG_LINK]: WrongLinkError,
  [ErrorVariant.LINK_ALREADY_USED]: WrongLinkError,
  [ErrorVariant.NOT_FOUND]: NotFoundError,
  [ErrorVariant.BLOCKED_COOKIES]: BlockedCookieError,
};

export const Error = ({ title, subtitle, variant, children }: ErrorProps) => {
  return (
    <Box sx={Styles.Wrapper(variant === ErrorVariant.NOT_FOUND)}>
      <Box component="img" alt="error" src={images[variant]} />
      <Typography variant="h1" sx={Styles.Title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" sx={Styles.Subtitle}>
          {subtitle}
        </Typography>
      )}
      {children}
    </Box>
  );
};

Error.variants = ErrorVariant;
