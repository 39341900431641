import { Styles } from '@percent/hosted-validation-form/theme/theme.types';
import { Theme } from '@mui/material';

export const Stepper: Styles = {
  width: '100%',

  '& > .MuiStepConnector-vertical.Mui-completed': {
    marginLeft: '14px',

    '> span': {
      borderLeftWidth: '2px',
      borderColor: (theme: Theme) => theme.palette.primary.light,
    },
  },
};

export const Label: Styles = {
  padding: '0',
};

export const Text: Styles = {
  display: 'inline-block',
  fontSize: '16px',
  fontWeight: '500',
};

export const Link: Styles = {
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: '500',
  textDecoration: 'underline',
  color: (theme: Theme) => theme.palette.grey['200'],
};
