import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card, Button, CountrySelect, CountryType } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import { useCountries } from '@percent/hosted-validation-form/app/HostedForm/useCountries/useCountries';
import * as Styles from '../HostedForm.styles';
import { HostedFormStep } from '../../HostedForm.types';

export const OrganisationOrigin = () => {
  const { country, setCountry, handleNext } = useValidationStepData();
  const { t } = useTranslation();
  const countries = useCountries();

  const onCountryChange = (newValue: CountryType | null) => setCountry(newValue?.code || '');

  return (
    <Card
      shouldContainBackButton={false}
      title={t('hostedValidation.title.country')}
      subTitle={t('hostedValidation.title.countrySub')}
    >
      <Box sx={Styles.FlexContainer}>
        <CountrySelect
          placeholder={t('hostedValidation.placeholder.selectCountry')}
          value={country}
          countries={countries}
          onChange={onCountryChange}
        />
      </Box>
      <Button isDisabled={!country} onClick={() => handleNext(HostedFormStep.SELECT_ORGANISATION)}>
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
