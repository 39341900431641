import { Box } from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { Card, Button, Input } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import * as Styles from '../HostedForm.styles';
import { HostedFormStep } from '../../HostedForm.types';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';
import { emailRegex } from '@percent/utility';

type FormFieldValues = {
  email: string;
  website?: string;
  withoutWebsite?: boolean;
  differentEmail?: string;
};

export const Email = () => {
  const { handleNext, handleBack } = useValidationStepData();
  const { t } = useTranslation();

  const {
    control,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
    setError,
    clearErrors,
  } = useFormContext<FormFieldValues>();
  const { partnerConfig } = usePartnerConfig();

  const handleNavigateNext = () => {
    const email = getValues('email');
    setValue('email', email);
    const hasPartnerFields = !!partnerConfig?.configuration?.partnerFields?.length;

    if (hasPartnerFields) {
      handleNext(HostedFormStep.PARTNER_FIELDS);
    } else {
      handleNext(HostedFormStep.TERMS);
    }
  };

  const reValidateEmail = (emailValue = getValues('email')) => {
    clearErrors('email');

    if (emailValue) {
      if (emailValue.length >= 255) {
        setError(
          'email',
          {
            type: 'max',
            message: t('hostedValidation.validation.maxLength', {
              fieldName: t('hostedValidation.label.email'),
              maxCharacterLimit: 255,
              interpolation: { escapeValue: false },
            }),
          },
          { shouldFocus: true },
        );
      } else {
        const emailSchema = object().shape({
          email: string().matches(emailRegex, t('hostedValidation.validation.emailValid')),
        });

        emailSchema
          .isValid({
            email: emailValue,
          })
          .then((valid) => {
            if (!valid) {
              setError(
                'email',
                { type: 'custom', message: t('hostedValidation.validation.emailValid') },
                { shouldFocus: true },
              );
            }
          });
      }
    } else {
      setError(
        'email',
        {
          type: 'required',
          message: t('hostedValidation.validation.required', {
            fieldName: t('hostedValidation.label.email'),
            interpolation: { escapeValue: false },
          }),
        },
        { shouldFocus: true },
      );
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    reValidateEmail(e.target.value);

    setValue('email', e.target.value, {
      shouldValidate: false,
      shouldDirty: true,
    });
  };

  return (
    <Card
      shouldContainBackButton
      goBack={handleBack}
      title={t('hostedValidation.title.verifyEmail')}
      subTitle={t('hostedValidation.title.enterEmail')}
    >
      <Box sx={Styles.FlexContainer}>
        <Controller
          name="email"
          control={control}
          render={({ field: { name, value } }) => (
            <Input
              name={name}
              value={value}
              onChange={handleEmailChange}
              label={t('hostedValidation.label.email')}
              placeholder={t('hostedValidation.placeholder.email')}
              errorMessage={errors.email?.message}
            />
          )}
        />
      </Box>
      <Button isDisabled={!!errors?.email?.message || !dirtyFields?.email} onClick={handleNavigateNext}>
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
