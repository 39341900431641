import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const ErrorMessage: Styles = {
  marginTop: '8px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '0.5px',
  color: (theme) => theme.palette.error.main,
};

export const Adornment: Styles = {
  backgroundColor: (theme) => theme.palette.grey['010'],
  height: '56px',
  maxHeight: '56px',
  marginRight: '-14px',
  border: '1px solid',
  borderColor: (theme) => theme.palette.grey['020'],
  boxSizing: 'border-box',
  borderRadius: '0px 4px 4px 0px',
  paddingX: '16px',
  zIndex: 1,

  '& p': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: (theme) => theme.palette.grey['160'],
  },
};

export const Input = (disabled: boolean, error: boolean, value: boolean): Styles => ({
  width: '100%',
  marginBottom: '16px',

  legend: {
    width: 0,
  },

  '.MuiInputLabel-root': {
    position: 'static',
    transform: 'none',
    marginY: '4px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: (theme) => `${theme.palette.grey['200']} !important`,
    whiteSpace: 'normal',
  },

  textarea: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.2px',
    color: (theme) => theme.palette.grey['200'],
  },

  input: {
    padding: '18px 16px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: (theme) => theme.palette.grey['200'],
    zIndex: 1,
    overflow: value ? 'visible' : 'hidden',
    textOverflow: value ? 'clip' : 'ellipsis',
    whiteSpace: value ? 'normal' : 'nowrap',
  },

  '&&& .MuiOutlinedInput-notchedOutline': {
    borderColor: ({ palette }) => (error ? palette.error.main : palette.grey['020']),
    ...(error && {
      borderWidth: error && '2px !important',
    }),
    backgroundColor: ({ palette }) => (disabled ? palette.grey['020'] : palette.grey['000']),
    zIndex: 0,
  },

  '&:hover': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: ({ palette }) => `${palette.primary.dark} !important`,
    },

    '.MuiInputAdornment-root': {
      borderColor: ({ palette }) => `${palette.primary.dark} !important`,
    },
  },

  '.Mui-focused > .MuiOutlinedInput-notchedOutline': {
    borderColor: ({ palette }) => `${palette.primary.main} !important`,
  },

  '.Mui-focused > .MuiInputAdornment-root': {
    borderColor: ({ palette }) => `${palette.primary.main} !important`,
    borderWidth: '2px',
  },

  '.MuiInputAdornment-root': {
    borderColor: ({ palette }) => (error ? palette.error.main : palette.grey['020']),
    ...(error && {
      borderWidth: error && '2px !important',
    }),
  },

  '&&& .MuiOutlinedInput-root.Mui-disabled, &&& .MuiOutlinedInput-input.Mui-disabled': {
    color: (theme) => `${theme.palette.grey['100']} !important`,
    textFillColor: ({ palette }) => `${palette.grey['100']} !important`,
    zIndex: 1,
  },

  '&&& .MuiOutlinedInput-input::-webkit-input-placeholder': {
    opacity: '1 !important',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: (theme) => theme.palette.grey['160'],
  },

  '&&& .MuiOutlinedInput-input::-moz-placeholder': {
    opacity: '1 !important',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: (theme) => theme.palette.grey['160'],
  },
});

export const EmailInput: Styles = {
  flexDirection: 'column',
  padding: 0,

  '.MuiInputAdornment-root': {
    margin: 0,
    width: '100%',
    borderBottom: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderRadius: '0px 0px 4px 4px',
  },

  input: {
    width: '-webkit-fill-available',
  },
};
