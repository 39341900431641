import { useQuery } from 'react-query';
import { useState } from 'react';

import { ClientResponse } from '@percent/hosted-validation-form/api/types/types';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import { useMutation } from '@percent/hosted-validation-form/hooks';
import { uploadOrganisationEvidenceAction } from '@percent/hosted-validation-form/api/actions/uploadOrganisationEvidenceAction';

import { GetRegistries } from './RegisterOrganisation.types';
import { RegisterOrganisation } from './RegisterOrganisation';

export const RegisterOrganisationContainer = () => {
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const { country } = useValidationStepData();
  const { mutateAsync, isLoading: isUploading } = useMutation('uploadEvidence', uploadOrganisationEvidenceAction, {
    isFileUpload: true,
  });
  const { data, isError, isFetching, refetch } = useQuery<ClientResponse<GetRegistries>>(
    `registries?countryCode=${country}`,
    {
      retry: false,
      onSuccess: () => setIsInitialFetch(false),
      enabled: !!country,
    },
  );

  return (
    <RegisterOrganisation
      refetchRegistries={refetch}
      isInitiallyFetching={isInitialFetch}
      isError={isError}
      isLoading={isFetching}
      isUploading={isUploading}
      onUpload={mutateAsync}
      registries={data?.data.data}
    />
  );
};
