export const fontFamilyName = 'Inter'

export const fontFaces = `
  @font-face {
    font-family: '${fontFamilyName}', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: '${fontFamilyName}', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: '${fontFamilyName}', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: '${fontFamilyName}', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`
export const PPRightGroteskFaces = `
  @font-face {
    font-family: 'PPRightGroteskMedium';
    src: url('./assets/fonts/PPRightGrotesk-Medium.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'PPRightGroteskCompactBlack';
    src: url('./assets/fonts/PPRightGrotesk-CompactBlack.otf') format('opentype');
    font-display: swap;
}
`
