import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom-v6';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { ClientResponse } from '@percent/hosted-validation-form/api/types/types';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';
import { storage, sessionStorageIsAvailable } from '@percent/hosted-validation-form/utils/storage';
import { NavigationStackContextController } from '@percent/hosted-validation-form/context/navigationStack/NavigationStackContextController/NavigationStackContextController';
import { TokenError } from '@percent/hosted-validation-form/errors/TokenError';
import { useHostedValidationsFormAnalytics } from '@percent/hosted-validation-form/hooks/useHostedValidationsFormAnalytics/useHostedValidationsFormAnalytics';

import { ValidationStepContextController } from './HostedForm/validationStepContext/validationStepContextController/ValidationStepContextController';
import { CountriesContext } from './CountriesContext/CountriesContext';
import { GetCountries, GetPartnerConfiguration } from './HostedForm.types';
import { HostedForm } from './HostedForm/HostedForm';

export const HostedFormContainer = () => {
  const { partnerConfig, setPartnerConfig } = usePartnerConfig();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { t } = useTranslation();
  const { track } = useHostedValidationsFormAnalytics();

  const {
    data: countriesData,
    isError: isCountriesError,
    isLoading: isCountriesLoading,
    error: countriesError,
  } = useQuery<ClientResponse<GetCountries>>('countries', {
    retry: false,
  });

  const { isError, isLoading, refetch, error } = useQuery<ClientResponse<GetPartnerConfiguration>>(
    `validation-invites/${id}/hosted-form-detail`,
    {
      retry: false,
      enabled: false,
    },
  );

  useEffect(() => {
    (async () => {
      try {
        const jwtToken = searchParams.get('token');
        if (!jwtToken) {
          throw new TokenError({ message: 'No token given' });
        }

        if (jwtToken && sessionStorageIsAvailable) {
          storage.setItem('jwtToken', jwtToken);
          const response = await refetch();

          if (response.data?.data) {
            setPartnerConfig(response.data.data.data);

            const favicon = document.getElementById('favicon') as HTMLLinkElement;
            const description = document.getElementById('description') as HTMLMetaElement;
            if (favicon && document && description) {
              favicon.href = response.data.data.data.configuration.logo;
              document.title = t('hostedValidation.site.title', { partner: response.data.data.data.partner.name });
              description.content = t('hostedValidation.site.contentApply', {
                partnerName: response.data.data.data.partner.name,
              });
            }
          }
        }
      } catch (error) {
        if (error instanceof TokenError) {
          return console.warn(error);
        }
        throw error;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      partnerConfig &&
      storage.getItem('validationInviteId') &&
      storage.getItem('validationInviteId') !== partnerConfig?.id
    ) {
      storage.setItem('validationInviteId', partnerConfig?.id);
      track('Validation Session Started', {});
    }
    if (partnerConfig && !storage.getItem('validationInviteId')) {
      storage.setItem('validationInviteId', partnerConfig?.id);
      track('Validation Session Started', {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerConfig]);

  return (
    <CountriesContext.Provider value={countriesData?.data.data}>
      <NavigationStackContextController>
        <ValidationStepContextController>
          <HostedForm
            isLoading={isLoading || isCountriesLoading}
            isError={(isError && (error as AxiosError)?.response?.status !== 500) || !searchParams.get('token')}
            isInternalError={
              (isError && (error as AxiosError)?.response?.status === 500) ||
              (isCountriesError && (countriesError as AxiosError)?.response?.status === 500)
            }
            isBlockedCookies={!sessionStorageIsAvailable}
          />
        </ValidationStepContextController>
      </NavigationStackContextController>
    </CountriesContext.Provider>
  );
};
