/* eslint-disable react-hooks/exhaustive-deps */
import {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  createContext,
} from 'react';
import styled from 'styled-components';
import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';

import { Toast } from './toast';
import { ToastVariant, ToastsQueueState } from './toast.types';

const TOAST_LIFETIME = 4000;
const TOAST_ANIMATION_DURATION = 400;

export const StyledMotionWrapper = styled(motion.div)`
  ${({ theme }) =>
    `
    z-index: 999999;
    position: fixed;
    bottom: ${theme.sizes.m};
    left: ${theme.sizes.m};
  `}
`;

interface ToastContextValues {
  addToast: (message: string, variant: ToastVariant) => void;
}

export const ToastContext = createContext<ToastContextValues>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addToast: () => {},
});

export function ToastProvider({ children }: { children: ReactNode }) {
  const [toastsQueue, setToastsQueue] = useState<ToastsQueueState>({
    queue: [],
  });
  let toastTimeout: number;
  let toastAnimationTimeout: number;

  const addToast = useCallback(
    (message: string, variant: ToastVariant) => {
      setToastsQueue((state) => ({
        ...state,
        queue: [...state.queue, { message, variant }],
      }));
    },
    [setToastsQueue]
  );

  const closeToast = () => {
    setToastsQueue((state) => ({
      active: undefined,
      queue: state.queue.slice(1),
    }));
  };

  const clearTimers = () => {
    clearTimeout(toastTimeout);
    clearTimeout(toastAnimationTimeout);
  };

  useEffect(() => {
    if (toastsQueue.queue.length > 0) {
      toastAnimationTimeout = window.setTimeout(() => {
        setToastsQueue((state) => ({
          ...state,
          active: toastsQueue.queue[0],
        }));
      }, TOAST_ANIMATION_DURATION);
    }
  }, [toastsQueue.queue]);

  useEffect(() => {
    if (toastsQueue.active) {
      clearTimers();

      toastTimeout = window.setTimeout(() => {
        closeToast();
      }, TOAST_LIFETIME);
    }

    return () => clearTimers();
  }, [toastsQueue.active]);

  return (
    <ToastContext.Provider value={{ addToast }}>
      <AnimatePresence>
        {toastsQueue.active ? (
          <FloatingPortal>
            <StyledMotionWrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                ease: 'easeInOut',
                duration: 0.2,
              }}
            >
              <Toast {...toastsQueue.active} onClose={closeToast} />
            </StyledMotionWrapper>
          </FloatingPortal>
        ) : null}
      </AnimatePresence>
      {children}
    </ToastContext.Provider>
  );
}
