import * as React from 'react';
import { Autocomplete, FormControl, InputLabel, TextField } from '@mui/material';

import * as Styles from '../Select.styles';

import { CountrySelectProps, CountryType } from './CountrySelect.types';

export const CountrySelect = ({
  onChange: onChangeInternal,
  countries,
  value,
  label,
  placeholder,
  isDisabled,
}: CountrySelectProps) => {
  let currentCountry: CountryType | string | undefined;
  if (typeof value === 'string') {
    currentCountry = (countries.find((item: CountryType) => item.code === value) as CountryType) || null;
  } else {
    currentCountry = value;
  }

  return (
    <FormControl sx={Styles.Wrapper(!!value)} fullWidth>
      {label && <InputLabel sx={Styles.Label}>{label}</InputLabel>}
      <Autocomplete
        disableClearable
        options={countries}
        disabled={isDisabled}
        autoHighlight
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: CountryType | null) => {
          onChangeInternal(newValue);
        }}
        fullWidth={true}
        value={currentCountry}
        isOptionEqualToValue={(option, value) => option.code === value.code || option.name === value.name}
        getOptionLabel={(country) => country.name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </FormControl>
  );
};
