import { BrowserRouter as Router } from 'react-router-dom-v6';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { ClientContextController } from '@percent/hosted-validation-form/context/client/clientContextController/ClientContextController';
import { ThemeContext } from '@percent/hosted-validation-form/context/theme/ThemeProvider/ThemeProvider';
import { PartnerConfigContextController } from '@percent/hosted-validation-form/context/partnerConfig/PartnerConfigContextController/PartnerConfigContextController';
import { FormContextController } from '@percent/hosted-validation-form/context/form/FormContextController/FormContextController';
import { AnalyticsContextController } from '@percent/hosted-validation-form/context/analytics/AnalyticsContextController/AnalyticsContextController';
import { i18n } from '@percent/hosted-validation-form/i18n/config';
import { logger } from '@percent/hosted-validation-form/utils/logger';

import { AppProvidersProps } from './AppProviders.types';
import { ErrorFallback } from './ErrorFallback';
import { LemonadeProvider } from '@percent/lemonade';
import { splitSdkConfig } from './SplitSdkProvider/SplitSdkProvider';
import { SplitFactory } from '@splitsoftware/splitio-react';

export const AppProviders = ({ children }: AppProvidersProps) => (
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <SplitFactory config={splitSdkConfig}>
      <I18nextProvider i18n={i18n}>
        <PartnerConfigContextController>
          <AnalyticsContextController
            logger={logger}
            writeKey={process.env.NX_REACT_APP_SEGMENT_WRITE_KEY}
            options={{ disableClientPersistence: true }}
          >
            <FormContextController>
              <ThemeContext>
                <LemonadeProvider>
                  <Router>
                    <ClientContextController>{children}</ClientContextController>
                  </Router>
                </LemonadeProvider>
              </ThemeContext>
            </FormContextController>
          </AnalyticsContextController>
        </PartnerConfigContextController>
      </I18nextProvider>
    </SplitFactory>
  </Sentry.ErrorBoundary>
);
