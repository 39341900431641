import { OutlinedInput, FormControl, InputLabel, InputAdornment, Typography, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CancelIcon } from '@percent/hosted-validation-form/assets/icons/cancel.svg';
import { ReactComponent as SearchIcon } from '@percent/hosted-validation-form/assets/icons/search.svg';
import * as InputStyles from '../Input/Input.styles';
import { isEllipsisActive } from '@percent/hosted-validation-form/utils/isEllipsisActive';

import { SearchInputProps } from './SearchInput.types';
import * as Styles from './SearchInput.styles';

export const SearchInput = ({
  value,
  name,
  onChange,
  label,
  placeholder,
  errorMessage,
  disabled,
  resultsCount,
  isFetched,
  onKeyPress,
}: SearchInputProps) => {
  const cancelSearch = () => onChange('');
  const { t } = useTranslation();

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    onChange(event.target.value);

  const startAdornment = (
    <InputAdornment position="start" sx={Styles.SearchIcon}>
      <SearchIcon />
    </InputAdornment>
  );

  const endAdornment = (
    <InputAdornment position="end">
      <Typography sx={Styles.ResultsCount}>
        {t('hostedValidation.typography.results', { count: resultsCount })}
      </Typography>
      <IconButton onClick={cancelSearch}>
        <CancelIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl sx={InputStyles.Input(!!disabled, !!errorMessage, !!value)} disabled={disabled}>
      <InputLabel sx={Styles.SearchInput} htmlFor={name}>
        {label}
      </InputLabel>
      <Tooltip title={isEllipsisActive(placeholder, name) && !value ? placeholder : ''} placement="top-start">
        <OutlinedInput
          error={!!errorMessage}
          value={value}
          id={name}
          name={name}
          placeholder={placeholder}
          label={label}
          onChange={onSearchChange}
          startAdornment={startAdornment}
          endAdornment={!disabled && isFetched && value && endAdornment}
          onKeyPress={onKeyPress}
        />
      </Tooltip>
      <Typography sx={InputStyles.ErrorMessage}>{errorMessage}</Typography>
    </FormControl>
  );
};
