import { AxiosRequestConfig } from 'axios';
import { Buffer } from 'buffer';

import { storage } from '@percent/hosted-validation-form/utils/storage';

const getToken = () => storage.getItem('jwtToken');

const getPartnerPublicKey = (token: string) => {
  if (token) {
    try {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).partnerPublicKey;
    } catch (e) {
      return '';
    }
  }
};

export const requestTokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const jwtToken = getToken();

  if (jwtToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: getPartnerPublicKey(jwtToken),
      },
      withCredentials: true,
    };
  }
  return config;
};
