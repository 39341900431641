import { string, number, array } from 'yup';
import { TFunction } from 'react-i18next';

import { FormField } from '@percent/hosted-validation-form/app/HostedForm/HostedForm.types';

export const partnerFieldValidator = (field: FormField, t: TFunction<'translation', undefined>) => {
  if (field.type === 'short_text') {
    if (field.validation?.required) {
      if (field.validation.maxLength) {
        return string()
          .required(
            t('hostedValidation.validation.required', {
              fieldName: field.title,
              interpolation: { escapeValue: false },
            }),
          )
          .max(
            field.validation.maxLength,
            t('hostedValidation.validation.maxLength', {
              fieldName: field.title,
              maxCharacterLimit: field.validation.maxLength,
              interpolation: { escapeValue: false },
            }),
          );
      } else {
        return string().required(
          t('hostedValidation.validation.required', { fieldName: field.title, interpolation: { escapeValue: false } }),
        );
      }
    } else {
      if (field.validation?.maxLength) {
        return string().max(
          field.validation.maxLength,
          t('hostedValidation.validation.maxLength', {
            fieldName: field.title,
            maxCharacterLimit: field.validation.maxLength,
            interpolation: { escapeValue: false },
          }),
        );
      } else {
        return string();
      }
    }
  } else if (field.type === 'multiple_choice') {
    if (field.validation?.required) {
      return array()
        .of(string())
        .required(
          t('hostedValidation.validation.required', { fieldName: field.title, interpolation: { escapeValue: false } }),
        );
    } else {
      return array().of(string());
    }
  } else if (field.type === 'number') {
    if (field.validation?.required) {
      return number().required(
        t('hostedValidation.validation.required', { fieldName: field.title, interpolation: { escapeValue: false } }),
      );
    } else {
      return number();
    }
  } else if (field.type === 'select') {
    if (field.validation?.required) {
      return string().required(
        t('hostedValidation.validation.required', { fieldName: field.title, interpolation: { escapeValue: false } }),
      );
    } else {
      return string();
    }
  }
  return string();
};
