export const redactToken = (input: string): string => {
  const tokenIndex = input.indexOf('token=');

  if (tokenIndex !== -1) {
    const substringIndex = tokenIndex + 6; // Length of 'token=' is 6
    const substring = input.substring(substringIndex);
    const replacedSubstring = substring.replace(/^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$/, 'redacted');
    return input.substring(0, substringIndex) + replacedSubstring;
  }

  return input;
};
