import {
  UseMutationResult,
  useMutation as useRQMutation,
  UseMutationOptions,
  MutationFunction,
  MutationKey,
} from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { useClient } from '@percent/hosted-validation-form/hooks';

import { MutationFn } from './useMutation.types';

export const useMutation = <TData = unknown, TError = unknown, TParams = unknown, TContext = unknown>(
  mutationKey: MutationKey,
  mutation: MutationFn<TParams, TData, TError>,
  options?: UseMutationOptions<TData, TError, TParams, TContext> & { isFileUpload?: boolean },
): UseMutationResult<TData, TError, TParams, TContext> => {
  const client = useClient();

  const mutationFn: MutationFunction<TData, TParams> = useCallback(
    (variables) => {
      const { endpoint, params, method } = mutation(variables);

      const axiosConfig: AxiosRequestConfig = {
        url: endpoint,
        data: params ? (options?.isFileUpload ? params : JSON.stringify(params)) : undefined,
        method: method || 'POST',
        ...(options?.isFileUpload && {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
      };

      return client.request(axiosConfig);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, mutation],
  );

  return useRQMutation<TData, TError, TParams, TContext>(mutationKey, mutationFn, options);
};
