import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const Wrapper = (isNotFoundVariant: boolean): Styles => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '48px',

  img: {
    marginBottom: isNotFoundVariant ? '4px' : '24px',
  },
});

export const Title: Styles = {
  color: (theme) => theme.palette.grey['200'],
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '16px',
  textAlign: 'center',
};

export const Subtitle: Styles = {
  color: (theme) => theme.palette.grey['160'],
  fontSize: '16px',
  fontWeight: '400',
  marginBottom: '24px',
  textAlign: 'center',
};
