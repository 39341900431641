import React from 'react'
import styled from 'styled-components'

import { Icon } from '../icon'
import { Text } from '../text'
import { ToastProps } from './toast.types'

export const StyledToast = styled.div`
  ${({ theme }) =>
    `
    box-sizing: border-box;
    width: 21.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: ${theme.borderRadii.medium};
    padding: ${theme.sizes.xs};
    background-color: ${theme.colors.white};
    font-weight: ${theme.fontWeights.medium};
    line-height: 1.5rem;
    box-shadow: ${theme.boxShadows.small};
  `}
`

export const StyledIconWrapper = styled.div`
  ${({ theme }) =>
    ` 
    margin-top: ${theme.sizes.xxs};
  `}
`

export const StyledContentWrapper = styled.div`
  ${({ theme }) =>
    `
    width: auto;
    max-width: 100%;
    margin: ${theme.sizes.xxs} auto ${theme.sizes.xxs} ${theme.sizes.xs};
  `}
`

export const StyledCloseButton = styled.button`
  ${({ theme }) =>
    `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: ${theme.sizes.xs};
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    width: ${theme.sizes.l};
    height:  ${theme.sizes.l};
  `}
`

export const Toast = ({ variant, message, onClose }: ToastProps) => (
  <StyledToast
    id="active-toast"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <StyledIconWrapper>
      <Icon
        name={variant === 'success' ? 'approve' : 'reject'}
        size={6}
        color={variant === 'success' ? 'success400' : 'alert400'}
      />
    </StyledIconWrapper>
    <StyledContentWrapper>
      <Text size="small" textWrap={true}>
        {message}
      </Text>
    </StyledContentWrapper>
    <StyledCloseButton onClick={onClose} data-testid="toast-close-btn">
      <Icon name="close" size={4} color="gray700" />
    </StyledCloseButton>
  </StyledToast>
)
