import { useEffect, useState } from 'react';

import { useInfiniteQuery } from '@percent/hosted-validation-form/hooks';
import { fetchInfiniteOrganisationsQuery } from '@percent/hosted-validation-form/api/actions/fetchOrganisationsAction';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import { useHostedValidationsFormAnalytics } from '@percent/hosted-validation-form/hooks/useHostedValidationsFormAnalytics/useHostedValidationsFormAnalytics';

import { SelectOrganisation } from './SelectOrganisation';

export const SelectOrganisationContainer = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [previousQuery, setPreviousQuery] = useState(searchQuery);
  const { country } = useValidationStepData();
  const { track } = useHostedValidationsFormAnalytics();

  const {
    data,
    isFetched,
    isFetchedAfterMount,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isError,
  } = useInfiniteQuery('organisations', fetchInfiniteOrganisationsQuery, {
    cursorKey: 'page',
    retry: false,
    enabled: false,
    args: {
      pageSize: 25,
      query: searchQuery,
      countryCode: country,
    },
    onSuccess: (data) => {
      track('Organisation Search Performed', {
        searchTerm: searchQuery,
        searchResultsCount: Number(data?.pages[0].data.totalResults) || 0,
        country,
      });
      setPreviousQuery(searchQuery);
    },
    getNextPageParam: (currentPage) => {
      if (!searchQuery) {
        return currentPage.data._links.next ? currentPage.data.pageNumber + 1 : undefined;
      }

      if (searchQuery) {
        if (searchQuery !== previousQuery) {
          if (!currentPage.data._links.current.split('query=')[1]) {
            return 1;
          }
        }

        if (currentPage.data._links.next) {
          return currentPage.data.pageNumber + 1;
        }

        return !currentPage.data._links.next ? undefined : 1;
      }

      return;
    },
  });

  useEffect(() => {
    (async () => {
      if (!isFetching && !isFetchingNextPage && !!searchQuery) {
        await refetch();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <SelectOrganisation
      data={data}
      isFetched={isFetched}
      isFetchedAfterMount={isFetchedAfterMount}
      isRefetching={isFetching && !isFetchingNextPage && isFetchedAfterMount}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      setSearchQuery={setSearchQuery}
      isError={isError}
      country={country}
    />
  );
};
