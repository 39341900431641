export enum HostedFormStep {
  // Keep proper order to easier maintain and handle progress
  ORGANISATION_ORIGIN = 'ORGANISATION_ORIGIN',
  SELECT_ORGANISATION = 'SELECT_ORGANISATION',
  REGISTER_ORGANISATION = 'REGISTER_ORGANISATION',
  ORGANISATION_ADDRESS = 'ORGANISATION_ADDRESS',
  ORGANISATION_RESULT = 'ORGANISATION_RESULT',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  WEBSITE = 'WEBSITE',
  EMAIL = 'EMAIL',
  PARTNER_FIELDS = 'PARTNER_FIELDS',
  TERMS = 'TERMS',
  COMPLETE = 'COMPLETE',
}

export type Categories = {
  id: string;
  name: string;
  imageUrl: string;
  createdAt: string;
};

export type Milestone = {
  id: number;
  amount: number;
  reward: string;
  currencyCode: string;
  organisationId: string;
};

export type Organisation = {
  id: string;
  name: string;
  displayName: string | null;
  description: string;
  countryCode: string;
  milestones: [Milestone?];
  categories: [Categories?];
  type: string;
  logo: string | null;
  images: string[];
  registry: string;
  registryId: string;
  website: string | null;
  address: string;
  postcode: string | null;
  registrationDate: string | null;
  currencyCode: string;
  trustees: string | null;
  claimedAt: string | null;
  createdAt: string;
  websites: string[];
  addressLine1: string;
  addressLine2: string;
};

export type GetOrganisationsConfiguration = {
  data: Organisation[];
  object: 'organisation';
  pageNumber: number;
  pageSize: number;
  totalResults: number;
  nextPage: string;
  _links: {
    current: string;
    next: string;
    prev: string;
  };
};
