import { FormEvent, ReactNode, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom-v6';
import { useTranslation } from 'react-i18next';

import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';
import { ProgressContextController } from '@percent/hosted-validation-form/context/progress/ProgressContextController/ProgressContextController';
import { HostedFormProps } from '../HostedForm.types';
import { Card, Error, Loader } from '@percent/hosted-validation-form/ui';
import { useNavigationStack } from '@percent/hosted-validation-form/hooks/useNavigationStack/useNavigationStack';

import { HostedFormStep } from './HostedForm.types';
import {
  OrganisationOrigin,
  SelectOrganisationContainer,
  OrganisationResult,
  ContactDetails,
  Email,
  TermsContainer,
  Complete,
  Website,
  RegisterOrganisationContainer,
  OrganisationAddress,
  PartnerFields,
} from './steps';
import * as Styles from './steps/HostedForm.styles';
import { ErrorCard } from '@percent/hosted-validation-form/ui/Card/ErrorCard';

const steps: { [key in HostedFormStep]: ReactNode } = {
  [HostedFormStep.PARTNER_FIELDS]: <PartnerFields />,
  [HostedFormStep.ORGANISATION_ORIGIN]: <OrganisationOrigin />,
  [HostedFormStep.SELECT_ORGANISATION]: <SelectOrganisationContainer />,
  [HostedFormStep.REGISTER_ORGANISATION]: <RegisterOrganisationContainer />,
  [HostedFormStep.ORGANISATION_ADDRESS]: <OrganisationAddress />,
  [HostedFormStep.ORGANISATION_RESULT]: <OrganisationResult />,
  [HostedFormStep.CONTACT_DETAILS]: <ContactDetails />,
  [HostedFormStep.WEBSITE]: <Website />,
  [HostedFormStep.EMAIL]: <Email />,
  [HostedFormStep.TERMS]: <TermsContainer />,
  [HostedFormStep.COMPLETE]: <Complete />,
};

export const HostedForm = ({ isError, isInternalError, isLoading, isBlockedCookies }: HostedFormProps) => {
  const { id } = useParams();
  const { partnerConfig } = usePartnerConfig();
  const { validationStep } = useNavigationStack();
  const { t, i18n } = useTranslation();
  const methods = useFormContext();

  useEffect(() => {
    if (i18n.language) {
      return methods.setValue('language', i18n.language);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    methods.setValue('validationInviteId', id);
  }, [id]);

  return (
    <ProgressContextController>
      <Box
        sx={Styles.Form}
        component="form"
        onSubmit={(event: FormEvent) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        {isLoading && !isError && !isInternalError && (
          <Card shouldContainBackButton={false}>
            <Loader />
          </Card>
        )}
        {!isLoading && partnerConfig?.status === 'complete' && (
          <ErrorCard
            title={t('hostedValidation.errorMessage.linkUsedTitle')}
            subtitle={t('hostedValidation.errorMessage.linkUsedSubtitle')}
            variant={Error.variants.LINK_ALREADY_USED}
          />
        )}
        {!isLoading && isInternalError && !isError && !isBlockedCookies && (
          <ErrorCard
            title={t('hostedValidation.errorMessage.somethingWentWrong')}
            subtitle={t('hostedValidation.errorMessage.reloadPage')}
            variant={Error.variants.INTERNAL}
          />
        )}
        {!isLoading && isError && !isInternalError && !isBlockedCookies && (
          <ErrorCard
            variant={Error.variants.WRONG_LINK}
            title={t('hostedValidation.errorMessage.invalidLink')}
            subtitle={t('hostedValidation.errorMessage.linkDoesntExist')}
          />
        )}
        {!isLoading && !isError && !isInternalError && isBlockedCookies && (
          <ErrorCard
            variant={Error.variants.BLOCKED_COOKIES}
            title={t('hostedValidation.errorMessage.blockedCookies')}
            subtitle={t('hostedValidation.errorMessage.allowCookies')}
          />
        )}
        {!isLoading && partnerConfig?.status === 'open' && !isError && steps[validationStep]}
      </Box>
    </ProgressContextController>
  );
};
