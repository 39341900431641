import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRight } from '@percent/hosted-validation-form/assets/icons/arrow-right.svg';

import { SearchResultProps } from './SearchResult.types';
import * as Styles from './SearchResult.styles';

export const SearchResult = ({
  name,
  registryId,
  onClick,
  address,
  addressLine1,
  addressLine2,
  notHoverable,
}: SearchResultProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const handleHover = () => setIsHovered(!isHovered);

  return (
    <Box sx={Styles.Wrapper(!!notHoverable)} onMouseEnter={handleHover} onMouseLeave={handleHover} onClick={onClick}>
      <Typography variant="h6" sx={Styles.Name}>
        {name}
      </Typography>
      <Box sx={Styles.TextWrapper}>
        <Typography variant="subtitle1" sx={Styles.Text}>
          {t('hostedValidation.typography.registeredNumber')}: {registryId}
        </Typography>
        {!addressLine1 && !addressLine2 && (
          <Typography variant="subtitle1" sx={Styles.Text}>
            {address}
          </Typography>
        )}
      </Box>
      {addressLine1 && addressLine2 && (
        <Box sx={Styles.TextWrapper}>
          <Typography variant="subtitle1" sx={Styles.Text}>
            {address}
          </Typography>
          <Typography variant="subtitle1" sx={Styles.Text}>
            {addressLine1}
          </Typography>
          <Typography variant="subtitle1" sx={Styles.Text}>
            {addressLine2}
          </Typography>
        </Box>
      )}
      {isHovered && !notHoverable && (
        <Box sx={Styles.Icon}>
          <ArrowRight />
        </Box>
      )}
    </Box>
  );
};
