import { useContext } from 'react';

import { ValidationStepContext } from '../ValidationStepContext';

export const useValidationStepData = () => {
  const context = useContext(ValidationStepContext);

  if (context === undefined) {
    throw new Error('useValidationStepData must be used within an ValidationStepContextController');
  }

  return context;
};
