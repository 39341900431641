import * as Sentry from '@sentry/react';

import { environments } from '@percent/hosted-validation-form/constants/environments';

// eslint-disable-next-line no-console
const consoleErr = (error: Error) => console.log(error);

export const logger = (error: Error) => {
  switch (process.env.NX_REACT_APP_ENV) {
    case environments.STAGING:
    case environments.PREPROD:
    case environments.PRODUCTION:
      return Sentry.captureException(error);
    default:
      return consoleErr(error);
  }
};
