import { useEffect } from 'react';
import { Card, Error } from '@percent/hosted-validation-form/ui';
import { ErrorCardProps } from './ErrorCard.types';
import { useHostedValidationsFormAnalytics } from '@percent/hosted-validation-form/hooks/useHostedValidationsFormAnalytics/useHostedValidationsFormAnalytics';

export const ErrorCard = ({ title, subtitle, variant }: ErrorCardProps) => {
  const { page, analyticsLoaded } = useHostedValidationsFormAnalytics();

  useEffect(() => {
    if (analyticsLoaded) {
      page(variant, {
        category: 'Error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, analyticsLoaded]);

  return (
    <Card shouldContainBackButton={false} isError={true}>
      <Error variant={variant} title={title} subtitle={subtitle} />
    </Card>
  );
};
