import { useContext } from 'react';

import { NavigationStackContext } from '@percent/hosted-validation-form/context/navigationStack/NavigationStackContext/NavigationStackContext';

export const useNavigationStack = () => {
  const context = useContext(NavigationStackContext);

  if (context === undefined) {
    throw new Error('useNavigationStack must be used within a NavigationStackContextController');
  }

  return context;
};
