import React, { useCallback, useMemo } from 'react';
import Axios from 'axios';
import { QueryClient, QueryClientProvider, QueryFunction } from 'react-query';
import { useTranslation } from 'react-i18next';

import { ClientResponse } from '@percent/hosted-validation-form/api/types/types';
import { ClientContext } from '../clientContext/ClientContext';
import { sessionStorageIsAvailable } from '@percent/hosted-validation-form/utils/storage';

import { responseFailureInterceptor, responseSuccessInterceptor } from './interceptors/responseInterceptors';
import { ClientProviderProps } from './ClientContextController.types';
import { requestTokenInterceptor } from './interceptors/requestInterceptors';
import { config } from '@percent/hosted-validation-form/config/config';

export const ClientContextController = ({ children }: ClientProviderProps) => {
  const { i18n } = useTranslation();

  const axios = useMemo(() => {
    const axios = Axios.create({
      baseURL: `${config.urls.api}`,
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    });

    axios.interceptors.request.use(requestTokenInterceptor);
    axios.interceptors.response.use(responseSuccessInterceptor, responseFailureInterceptor);

    return axios;
  }, [i18n.language]);

  const queryFn: QueryFunction<ClientResponse> = useCallback(
    async ({ queryKey: [url] }) => {
      if (typeof url === 'string') {
        return await axios.get<ClientResponse>(url);
      }
      throw new Error('Invalid QueryKey');
    },
    [axios],
  );

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          queryFn,
          enabled: sessionStorageIsAvailable,
        },
      },
    });
  }, [queryFn]);

  return (
    <ClientContext.Provider value={axios}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ClientContext.Provider>
  );
};
