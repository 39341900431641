import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BasicCardProps } from './BasicCard.types';
import * as Styles from './Card.styles';
import { CardFooter } from './CardFooter';

//TODO: abstract to a base level component for rendering cards and a higher-level card and an error card passing different things
export const BasicCard = ({ children }: BasicCardProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Card}>
      <Box sx={Styles.Children}>{children}</Box>
      <Box sx={Styles.Divider} />
      <CardFooter />
    </Box>
  );
};
