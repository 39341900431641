import { createRoot } from 'react-dom/client';
import { StrictMode, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom-v6';

import { AppRoutes } from '@percent/hosted-validation-form/routing/AppRoutes';

import { reportWebVitals } from './reportWebVitals';
import { AppProviders } from './providers/AppProviders';

import './index.css';
import { environments } from '@percent/hosted-validation-form/constants/environments';
import { environment } from '@percent/hosted-validation-form/config/config';

if (
  [environments.PRODUCTION, environments.STAGING, environments.PREPROD, environments.QA, environments.SANDBOX].includes(
    environment as string,
  )
) {
  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      Sentry.captureConsoleIntegration({ levels: ['warn'] }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.33,
    environment: process.env.NX_REACT_APP_ENV,
    release: process.env.NX_VERCEL_GIT_COMMIT_SHA,
    allowUrls: [/https:\/\/*validation.poweredbypercent.com/, /https:\/\/hosted-validation-form-*-percent.vercel.app/],
  });
}

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <StrictMode>
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
