import { ReactNode } from 'react';

export enum ErrorVariant {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  WRONG_LINK = 'WRONG_LINK',
  BLOCKED_COOKIES = 'BLOCKED_COOKIES',
  LINK_ALREADY_USED = 'LINK_ALREADY_USED',
}

export type ErrorProps = {
  title: string;
  variant: ErrorVariant;
  subtitle?: string | null;
  children?: ReactNode;
};
