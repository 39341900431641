import { QueryFunction, QueryKey, useInfiniteQuery as useRQInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { useCallback } from 'react';
import { stringify } from 'qs';

import { useClient } from '@percent/hosted-validation-form/hooks/useClient/useClient';

import { InfiniteQueryFn, UseInfiniteQueryOptions } from './useInfiniteQuery.types';

export const useInfiniteQuery = <TArgs = unknown, TParams = unknown, TError = unknown, TResponse = TParams>(
  queryKey: QueryKey,
  query: InfiniteQueryFn<TArgs, TParams, TResponse>,
  options?: UseInfiniteQueryOptions<TArgs, TParams, TError, TResponse>,
): UseInfiniteQueryResult<TResponse, TError> => {
  const client = useClient();

  const { endpoint, args } = query(options?.args);

  const queryFn: QueryFunction<TParams> = useCallback(
    ({ pageParam = 1 }) => {
      const cursorKey = options?.cursorKey;

      return client.get(`${endpoint}?${cursorKey}=${pageParam}&${stringify(args, { addQueryPrefix: false })}`);
    },
    [args, client, endpoint, options?.cursorKey],
  );

  return useRQInfiniteQuery<TParams, TError, TResponse, QueryKey>(queryKey, queryFn, options);
};
