import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, Card, SearchResult } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import * as Styles from '../HostedForm.styles';
import { HostedFormStep } from '../../HostedForm.types';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';

export const OrganisationResult = () => {
  const { organisation, handleBack, handleNext } = useValidationStepData();
  const { partnerConfig } = usePartnerConfig();

  const { t } = useTranslation();

  const handleNavigateNext = () => {
    if (organisation?.website) {
      if (
        partnerConfig?.configuration.avCheckAvailable !== undefined &&
        !partnerConfig?.configuration.avCheckAvailable
      ) {
        const hasPartnerFields = !!partnerConfig?.configuration?.partnerFields?.length;
        if (hasPartnerFields) {
          return handleNext(HostedFormStep.PARTNER_FIELDS);
        }
        return handleNext(HostedFormStep.TERMS);
      } else {
        return handleNext(HostedFormStep.CONTACT_DETAILS);
      }
    } else {
      return handleNext(HostedFormStep.WEBSITE);
    }
  };

  return (
    <Card shouldContainBackButton goBack={handleBack} title={t('hostedValidation.title.isThisNonprofit')}>
      <Box sx={Styles.FlexContainer}>
        {organisation && (
          <SearchResult
            name={organisation?.name}
            registryId={organisation?.registryId}
            address={organisation?.address}
            addressLine1={organisation?.addressLine1}
            addressLine2={organisation?.addressLine2}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
            notHoverable
          />
        )}
      </Box>
      <Button isDisabled={false} onClick={handleNavigateNext}>
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
