import { useState, useEffect, useCallback } from 'react';
import { AnalyticsBrowser, Analytics } from '@segment/analytics-next';

import { AnalyticsContext } from '../AnalyticsContext/AnalyticsContext';

import { AnalyticsContextControllerProps } from './AnalyticsContextController.types';

export const AnalyticsContextController = ({
  writeKey,
  logger,
  options,
  children,
}: AnalyticsContextControllerProps) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  const loadAnalytics = useCallback(async () => {
    try {
      if (!writeKey || analytics) {
        return;
      }

      const [response] = await AnalyticsBrowser.load({ writeKey: writeKey }, options);

      setAnalytics(response);
    } catch (error) {
      logger(error);
    }
  }, [writeKey]);

  useEffect(() => {
    loadAnalytics();
  }, [writeKey]);

  return <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>;
};
