import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const SearchForm: Styles = {
  width: '100%',
  marginBottom: {
    xs: '32px',
    md: '40px',
  },
};

export const AddNonprofit: Styles = {
  position: 'absolute',
  bottom: 0,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '24px',
  textAlign: 'center',
  letterSpacing: '0.2px',
  color: (theme) => theme.palette.primary.main,
  margin: {
    xs: '24px 0px 8px',
    md: '24px 0px 12px',
  },
};

export const FlexContainer: Styles = {
  width: '100%',
  flexGrow: 1,
};

export const OrganisationAddressContainer: Styles = {
  width: '100%',
  flexGrow: 1,
  marginBottom: '56px',
};

export const RegisterButtonContainer: Styles = {
  margin: {
    xs: '80px 0 20px 0',
    md: '80px 0 0 0',
  },
};

export const Terms: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '368px',
  flexGrow: 1,

  '.MuiStepper-root': {
    margin: {
      xs: '36px 0 40px 0',
      md: '36px 0 72px 0',
    },
  },
};

export const CompleteIcon: Styles = {
  marginTop: '36px',
  marginBottom: '16px',
};

export const SearchResultsContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '370px',
  height: '100%',
  overflow: 'auto',
  width: '100%',
  marginBottom: '60px',

  div: {
    marginBottom: '8px',
  },

  ul: {
    padding: 0,
  },
};

export const GradientLayer: Styles = {
  display: 'flex',
  margin: '-140px 0 60px 0',
  zIndex: 1,
  height: '80px',
  width: '100%',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
};

export const Form: Styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: {
    xs: 'calc(100vw - 64px)',
    md: '752px',
  },
  width: {
    xs: '100%',
    sm: '720px',
    md: '752px',
  },
  paddingX: {
    xs: '16px',
    md: '0px',
  },
};

export const MenuItem: Styles = {
  position: 'sticky',
  bottom: '0px',
  padding: '16px',
  backgroundColor: 'white',

  '&.Mui-selected': {
    backgroundColor: '#f0f1fe',

    '&:hover': {
      backgroundColor: '#f0f1fe',
    },
  },

  '&:hover': {
    backgroundColor: 'white',
  },

  '& > svg': {
    marginRight: '12px',
  },

  '::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '16px',
    right: '16px',
    height: '1px',
    backgroundColor: (theme) => theme.palette.grey['020'],
  },
};

export const CheckboxLabel: Styles = {
  position: 'static',
  transform: 'none',
  marginY: '4px',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
  color: (theme) => `${theme.palette.grey['200']} !important`,
  whiteSpace: 'break-spaces',
};

export const Label: Styles = {
  padding: 0,
  margin: 0,
  display: 'inline-block',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.5px',
  color: (theme) => theme.palette.grey['160'],

  svg: {
    width: '24px !important',
    height: '24px !important',
  },

  path: {
    fill: (theme) => `${theme.palette.grey['100']} !important`,
  },
};

export const Link: Styles = {
  margin: '0 !important',
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'underline',
  color: (theme) => theme.palette.grey['200'],
};
