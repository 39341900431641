import { Box, Typography, InputLabel } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom-v6';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@percent/hosted-validation-form/ui/Button/Button';

import * as Styles from './FileUpload.styles';
import { FileUploadProps } from './FileUpload.types';

export const FileUpload = ({
  name,
  label,
  onUpload,
  helperText,
  maxFileSize = 5000000,
  acceptFileTypes = 'image/jpeg,application/pdf,image/png',
  isUploading,
}: FileUploadProps) => {
  const { id } = useParams();
  const { setValue, watch } = useFormContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files || !e.target?.files?.length || e.target.files.length > 1) {
      return undefined;
    }

    if (!acceptFileTypes.split(',').includes(e.target.files[0].type)) {
      setErrorMessage(t('hostedValidation.validation.invalidFileFormat'));
      return undefined;
    }

    if (e.target.files[0].size >= maxFileSize) {
      setErrorMessage(t('hostedValidation.validation.fileSize'));
      return undefined;
    }

    setErrorMessage('');

    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('validationInviteId', id ?? '');

    try {
      await onUpload(formData);
      setValue('file', e.target.files[0].name);
    } catch (error) {
      setErrorMessage(t('hostedValidation.errorMessage.somethingWentWrong'));
      return undefined;
    }

    return undefined;
  };

  return (
    <>
      <Typography sx={Styles.Label}>{label}</Typography>
      <Box sx={Styles.UploadContainer}>
        <Typography sx={Styles.FileName}>{watch('file') ?? t('hostedValidation.validation.noFile')}</Typography>
        <InputLabel htmlFor={name}>
          <input id={name} type="file" onChange={onFileChange} accept={acceptFileTypes} />
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <Button isDisabled={isUploading} onClick={() => {}} component="span">
            {isUploading ? t('hostedValidation.typography.uploading') : t('hostedValidation.typography.chooseFile')}
          </Button>
        </InputLabel>
      </Box>
      <Typography sx={Styles.ErrorMessage}>{errorMessage}</Typography>
      <Typography sx={Styles.HelperText}>{helperText}</Typography>
    </>
  );
};
