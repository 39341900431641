import { useContext } from 'react';

import { CountriesContext } from '../CountriesContext/CountriesContext';

export const useCountries = () => {
  const context = useContext(CountriesContext);

  if (context === undefined) {
    throw new Error('useCountries must be used within a CountriesContext.Provider');
  }

  return context;
};
