import { useState } from 'react';

import { PartnerConfiguration } from '@percent/hosted-validation-form/app/HostedForm/HostedForm.types';
import { PartnerConfigContext } from '../PartnerConfigContext/PartnerConfigContext';

import { PartnerConfigContextControllerProps } from './PartnerConfigContextController.types';

export const PartnerConfigContextController = ({ children }: PartnerConfigContextControllerProps) => {
  const [partnerConfig, setPartnerConfig] = useState<PartnerConfiguration | undefined>(undefined);

  return (
    <PartnerConfigContext.Provider value={{ partnerConfig, setPartnerConfig }}>
      {children}
    </PartnerConfigContext.Provider>
  );
};
