import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Card, Input, Button } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import * as Styles from '../HostedForm.styles';
import { HostedFormStep } from '../../HostedForm.types';

type FormFieldValues = {
  firstName: string;
  lastName: string;
};

export const ContactDetails = () => {
  const { handleNext, handleBack } = useValidationStepData();
  const {
    control,
    formState: { errors, dirtyFields },
  } = useFormContext<FormFieldValues>();
  const { t } = useTranslation();

  const handleNavigateNext = () => handleNext(HostedFormStep.EMAIL);

  return (
    <Card
      shouldContainBackButton
      goBack={handleBack}
      title={t('hostedValidation.title.provideContactDetails')}
      subTitle={t('hostedValidation.title.willUseThisInfo')}
    >
      <Box sx={Styles.FlexContainer}>
        <Controller
          name="firstName"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Input
              value={value}
              name={name}
              label={t('hostedValidation.label.firstName')}
              placeholder={t('hostedValidation.placeholder.firstName')}
              onChange={onChange}
              errorMessage={errors.firstName?.message}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{ required: true, maxLength: 255 }}
          render={({ field: { name, value, onChange } }) => (
            <Input
              value={value}
              name={name}
              label={t('hostedValidation.label.lastName')}
              placeholder={t('hostedValidation.placeholder.lastName')}
              onChange={onChange}
              errorMessage={errors.lastName?.message}
            />
          )}
        />
      </Box>
      <Button
        isDisabled={
          !!errors['firstName'] || !!errors['lastName'] || !(dirtyFields['firstName'] && dirtyFields['lastName'])
        }
        onClick={handleNavigateNext}
      >
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
