import { useEffect, useState } from 'react';

import { NavigationStackContext } from '../NavigationStackContext/NavigationStackContext';
import { HostedFormStep } from '@percent/hosted-validation-form/app/HostedForm/HostedForm/HostedForm.types';
import { useHostedValidationsFormAnalytics } from '@percent/hosted-validation-form/hooks/useHostedValidationsFormAnalytics/useHostedValidationsFormAnalytics';

import { NavigationStackContextControllerProps } from './NavigationStackContextController.types';

export const NavigationStackContextController = ({
  defaultValidationStep = HostedFormStep.ORGANISATION_ORIGIN,
  children,
}: NavigationStackContextControllerProps) => {
  const [validationStep, setValidationStep] = useState<HostedFormStep>(defaultValidationStep);
  const [navigationStack, setNavigationStack] = useState<HostedFormStep[]>([HostedFormStep.ORGANISATION_ORIGIN]);
  const { page, track, analyticsLoaded } = useHostedValidationsFormAnalytics();

  useEffect(() => {
    if (analyticsLoaded) {
      page(validationStep, {
        category: 'Step',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationStep, analyticsLoaded]);

  const handleNext = (step: HostedFormStep) => {
    track('Form submitted', { step: validationStep });
    setNavigationStack(() => [...navigationStack, step]);
    return setValidationStep(step);
  };

  const handleBack = () => {
    setNavigationStack(() => {
      const newStack = [...navigationStack];
      newStack.pop();
      setValidationStep(newStack[newStack.length - 1]);

      return newStack;
    });
  };

  return (
    <NavigationStackContext.Provider value={{ validationStep, handleNext, handleBack }}>
      {children}
    </NavigationStackContext.Provider>
  );
};
