import { Outlet, Route, Routes } from 'react-router-dom-v6';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { Error, Layout } from '@percent/hosted-validation-form/ui';
import { HostedFormContainer } from '@percent/hosted-validation-form/app/HostedForm/HostedFormContainer';

import { AppRoute } from './AppRoute.enum';
import { ErrorCard } from '@percent/hosted-validation-form/ui/Card/ErrorCard';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes = () => {
  const { t } = useTranslation();

  return (
    <SentryRoutes>
      <Route path={AppRoute.HOME} element={<Layout />}>
        <Route
          index
          element={
            <ErrorCard
              variant={Error.variants.WRONG_LINK}
              title={t('hostedValidation.errorMessage.invalidLink')}
              subtitle={t('hostedValidation.errorMessage.linkDoesntExist')}
            />
          }
        />
        <Route path={AppRoute.VALIDATE} element={<Outlet />}>
          <Route
            index
            element={
              <ErrorCard
                variant={Error.variants.WRONG_LINK}
                title={t('hostedValidation.errorMessage.invalidLink')}
                subtitle={t('hostedValidation.errorMessage.linkDoesntExist')}
              />
            }
          />
          <Route path={AppRoute.VALIDATE_ID} element={<HostedFormContainer />} />
        </Route>
        <Route
          path={AppRoute.NOT_FOUND}
          element={
            <ErrorCard
              variant={Error.variants.WRONG_LINK}
              title={t('hostedValidation.errorMessage.invalidLink')}
              subtitle={t('hostedValidation.errorMessage.linkDoesntExist')}
            />
          }
        />
      </Route>
    </SentryRoutes>
  );
};
