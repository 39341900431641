import { useEffect, useState } from 'react';

import { ProgressContext } from '../ProgressContext/ProgressContext';
import { ProgressContextProps } from '../ProgressContext/ProgressContext.types';
import { useValidationStepData } from '@percent/hosted-validation-form/app/HostedForm/HostedForm/validationStepContext/useValidationStepData/useValidationStepData';
import { HostedFormStep } from '@percent/hosted-validation-form/app/HostedForm/HostedForm/HostedForm.types';

const MAX_PROGRESS = Object.keys(HostedFormStep).length - 1;

export const ProgressContextController = ({ children }: ProgressContextProps) => {
  const { validationStep } = useValidationStepData();
  const [progress, setProgress] = useState((1 / MAX_PROGRESS) * 100);

  useEffect(() => {
    if (validationStep) {
      const currentStepIndex = Object.keys(HostedFormStep).indexOf(validationStep);

      return setProgress((currentStepIndex / MAX_PROGRESS) * 100);
    }
  }, [validationStep]);

  return <ProgressContext.Provider value={{ progress, setProgress }}>{children}</ProgressContext.Provider>;
};
