import { FormControlLabel, Checkbox as BaseCheckbox } from '@mui/material';

import { CheckboxProps } from './Checkbox.types';
import * as Styles from './Checkbox.styles';

export const Checkbox = ({ checked, name, label, onChange, disabled }: CheckboxProps) => {
  return (
    <FormControlLabel
      sx={Styles.Checkbox(checked)}
      name={name}
      disabled={disabled}
      control={<BaseCheckbox checked={checked} onChange={onChange} />}
      label={label}
    />
  );
};
