import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const Wrapper = (notHoverable: boolean): Styles => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: {
    xs: '16px',
    md: '24px',
  },
  borderRadius: '4px',
  backgroundColor: (theme) => theme.palette.grey['010'],

  '&:hover': {
    backgroundColor: ({ palette }) => (!notHoverable ? palette.grey['020'] : palette.grey['010']),
  },
});

export const TextWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
};

export const Name: Styles = {
  fontSize: '16px',
  fontWeight: '500',
  color: (theme) => theme.palette.grey['200'],
  marginBottom: '16px',
};

export const Text: Styles = {
  fontSize: '14px',
  fontWeight: '400',
  color: (theme) => theme.palette.grey['160'],
  wordWrap: 'break-word',
};

export const Icon: Styles = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '24px',
  color: (theme) => theme.palette.grey['100'],
};
