import { Box, InputLabel } from '@mui/material';
import { Control, Controller, FieldErrors, FieldValues, useFormContext, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Card, Input, Button, Checkbox, Select } from '@percent/hosted-validation-form/ui';
import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import * as Styles from '../HostedForm.styles';
import { HostedFormStep } from '../../HostedForm.types';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';

type RenderFieldProps = {
  type: 'short_text' | 'multiple_choice' | 'number' | 'select';
  id: string;
  title: string;
  control: Control<FieldValues, object>;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  placeholder?: string;
  options?: {
    key: string;
    value: string;
  }[];
};

const RenderField = ({ type, id, title, control, errors, setValue, placeholder, options }: RenderFieldProps) => {
  if (type === 'multiple_choice') {
    return (
      <Controller
        name={`partnerFields.${id}`}
        control={control}
        render={({ field: { value } }) => (
          <>
            <InputLabel htmlFor={id} sx={Styles.CheckboxLabel}>
              {title}
            </InputLabel>
            {options?.map(({ key, value: optionValue }) => (
              <Checkbox
                key={key}
                checked={value?.includes(optionValue)}
                name={key}
                label={key}
                onChange={(event) => {
                  const currentValue = value;
                  let newArray = [];

                  if (currentValue && event.target.checked && Array.isArray(currentValue)) {
                    newArray = [...currentValue, optionValue];
                  } else if (currentValue && !event.target.checked && Array.isArray(currentValue)) {
                    newArray = [...currentValue].filter((el) => el !== optionValue);
                  } else {
                    newArray = [optionValue];
                  }
                  setValue(`partnerFields.${id}`, newArray, {
                    shouldValidate: true,
                  });
                }}
                disabled={false}
              />
            ))}
          </>
        )}
      />
    );
  } else if (type === 'select') {
    return (
      <Controller
        name={`partnerFields.${id}`}
        control={control}
        defaultValue=""
        render={({ field: { value } }) => (
          <Select
            id={`partnerFields.${id}`}
            label={title}
            placeholder={placeholder ?? ''}
            value={value}
            onChange={(event) =>
              setValue(`partnerFields.${id}`, event.target.value, {
                shouldValidate: true,
              })
            }
          >
            {options?.map((option) => Select.renderMenuItem(option.value, option.key))}
          </Select>
        )}
      />
    );
  }

  return (
    <Controller
      name={`partnerFields.${id}`}
      control={control}
      render={({ field }) => (
        <Input
          type={type === 'number' ? 'number' : undefined}
          value={field.value || ''}
          label={title}
          placeholder={placeholder ?? ''}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errorMessage={(errors.partnerFields as any)?.[id]?.message}
          onChange={(event) => {
            const value = type === 'number' ? Number(event.target.value) : event.target.value;
            setValue(`partnerFields.${id}`, value, {
              shouldValidate: true,
            });
          }}
          name={id}
        />
      )}
    />
  );
};

export const PartnerFields = () => {
  const { handleNext, handleBack } = useValidationStepData();
  const { control, getValues, setValue, formState } = useFormContext();
  const { partnerConfig } = usePartnerConfig();
  const { t } = useTranslation();

  const handleNavigateNext = () => {
    handleNext(HostedFormStep.TERMS);
  };

  const checkDisabled = () => {
    let isDisabled = false;
    if (Object.keys(formState.errors).length > 0) {
      isDisabled = true;
    }
    partnerConfig?.configuration.partnerFields.map((field) => {
      const currentValue = getValues(`partnerFields.${field.id}`);
      if (field.validation?.required && !currentValue) {
        isDisabled = true;
      }

      if (
        field.validation?.required &&
        field.type === 'multiple_choice' &&
        Array.isArray(currentValue) &&
        currentValue.length === 0
      ) {
        isDisabled = true;
      }
    });
    return isDisabled;
  };

  return (
    <Card shouldContainBackButton goBack={handleBack} title={t('hostedValidation.title.almostThere')}>
      <Box sx={Styles.FlexContainer}>
        {partnerConfig?.configuration.partnerFields.map(({ id, type, title, placeholder, options }) => (
          <RenderField
            key={id}
            type={type}
            id={id}
            title={title}
            placeholder={placeholder}
            options={options}
            control={control}
            errors={formState.errors}
            setValue={setValue}
          />
        ))}
      </Box>
      <Button isDisabled={checkDisabled()} onClick={handleNavigateNext}>
        {t('hostedValidation.button.next')}
      </Button>
    </Card>
  );
};
