import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const Checkbox = (checked: boolean): Styles => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',

  '.MuiTypography-root': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    color: (theme) => theme.palette.grey['160'],
    marginTop: '15px',
  },

  svg: {
    width: '32px',
    height: '32px',
  },

  path: {
    fill: ({ palette }) => (checked ? palette.primary.main : palette.grey['020']),
  },

  '&:hover': {
    path: {
      fill: ({ palette }) => (checked ? palette.primary.main : palette.primary.dark),
    },
  },
});
