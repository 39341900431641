import { ThemeProvider } from '@mui/material';
import tinycolor from 'tinycolor2';

import { theme } from '@percent/hosted-validation-form/theme/theme';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';

import { ThemeContextProps } from './ThemeProvider.types';

export const ThemeContext = ({ children }: ThemeContextProps) => {
  const { partnerConfig } = usePartnerConfig();
  const isPartnerColorDark = tinycolor(partnerConfig?.configuration.primaryColor).isDark();

  return (
    <ThemeProvider
      theme={
        partnerConfig
          ? {
              ...theme,
              palette: {
                ...theme.palette,
                clientColor: partnerConfig?.configuration.primaryColor,
                // TODO this is a POC, implement it properly
                detectedTextColor: isPartnerColorDark ? 'white' : 'dark',
              },
            }
          : theme
      }
    >
      {children}
    </ThemeProvider>
  );
};
