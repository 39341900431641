import { ThemeProvider } from '@mui/system';

import { Error, Layout } from '@percent/hosted-validation-form/ui';
import { theme } from '@percent/hosted-validation-form/theme/theme';
import { BasicCard } from '@percent/hosted-validation-form/ui/Card/BasicCard';
import { i18n } from '@percent/hosted-validation-form/i18n/config';

export const ErrorFallback = () => {
  const { t } = i18n;
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <BasicCard>
          <Error
            title={t('hostedValidation.errorMessage.somethingWentWrong')}
            subtitle={t('hostedValidation.errorMessage.reloadPage')}
            variant={Error.variants.INTERNAL}
          />
        </BasicCard>
      </Layout>
    </ThemeProvider>
  );
};
