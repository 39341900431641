import { AxiosError } from 'axios';

import { MutationFn } from '@percent/hosted-validation-form/hooks/useMutation/useMutation.types';
import { ClientResponse } from '../types/types';
import {
  UploadOrganizationEvidenceArguments,
  UploadOrganizationEvidenceResponse,
} from '@percent/hosted-validation-form/app/HostedForm/HostedForm/steps/registerOrganisation/RegisterOrganisation.types';

export const uploadOrganisationEvidenceAction: MutationFn<
  UploadOrganizationEvidenceArguments,
  ClientResponse<UploadOrganizationEvidenceResponse>,
  AxiosError
> = (body) => {
  return {
    endpoint: '/validation-submission-documents',
    method: 'POST',
    params: body,
  };
};
