import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const LoaderContainer: Styles = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',

  '&&& span:first-of-type': {
    color: (theme) => theme.palette.grey['010'],
    margin: '56px auto 0',
    position: 'absolute',
    width: '30px !important',
    height: '30px !important',
    animation: 'none',

    'svg > circle': {
      strokeDasharray: '142px',
      animation: 'none',
    },
  },

  '&&& span:nth-of-type(2)': {
    color: (theme) => theme.palette.grey['100'],
    margin: '56px auto 0',
    position: 'absolute',
    width: '30px !important',
    height: '30px !important',

    'svg > circle': {
      strokeLinecap: 'round !important',
    },
  },
};
