import { string } from 'yup';

export const isValidWebsiteURL = (validationMessage: string) =>
  string()
    .transform((a) => {
      if (a == null) return a;
      if (!/^(?:ht)tps?:\/\//.test(a)) {
        return `http://${a}`; //TODO: Why do we always add HTTP instead of secure HTTPS ?
      }
      return a;
    })
    .test('is-website-url', validationMessage, async function isWebsiteUrl(value) {
      if (value == null) return true;

      const isValidURL = await string().url().isValid(value);
      if (!isValidURL) {
        return false;
      }

      const matches = [...value.matchAll(/^(?:https?:\/\/)?([^/?\n]+)/gim)];
      if (matches[0][1].indexOf('@') !== -1) {
        return false;
      }

      return true;
    });
