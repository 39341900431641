import { Styles } from '@percent/hosted-validation-form/theme/theme.types';

export const MenuItem: Styles = {
  height: '48px',

  '&:hover': {
    backgroundColor: (theme) => theme.palette.grey['010'],
    borderLeft: (theme) => `2px solid ${theme.palette.grey['100']}`,
  },
};
