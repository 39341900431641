import { MenuItem } from '@mui/material';

import * as Styles from './MenuItem.styles';

export const renderMenuItem = (value: string, label: string, testId?: string) => {
  return (
    <MenuItem sx={Styles.MenuItem} value={value} key={value} data-testid={testId}>
      {label}
    </MenuItem>
  );
};
