import { Box, Typography, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressBar } from '@percent/hosted-validation-form/ui';
import { ReactComponent as ArrowLeft } from '@percent/hosted-validation-form/assets/icons/arrow-left.svg';
import { usePartnerConfig } from '@percent/hosted-validation-form/hooks';

import { CardProps } from './Card.types';
import * as Styles from './Card.styles';
import { CardFooter } from './CardFooter';

export const Card = ({ shouldContainBackButton, children, title, subTitle, isError, goBack }: CardProps) => {
  const { partnerConfig, setPartnerConfig } = usePartnerConfig();
  const { t } = useTranslation();

  useEffect(() => {
    if (isError && partnerConfig) {
      setPartnerConfig({
        ...partnerConfig,
        configuration: { ...partnerConfig.configuration, primaryColor: '#ffffff' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Box sx={Styles.Card}>
      {partnerConfig && !isError && (
        <Box sx={Styles.Logo} component="img" alt="logo" src={partnerConfig?.configuration.logo} />
      )}
      {shouldContainBackButton && (
        <IconButton onClick={goBack} sx={Styles.BackButton} aria-label="back" data-testid="card-back-button">
          <ArrowLeft />
        </IconButton>
      )}
      {partnerConfig && !isError && <ProgressBar />}
      {title && <Typography sx={Styles.Title(!!subTitle)}>{title}</Typography>}
      {subTitle && <Typography sx={Styles.SubTitle}>{subTitle}</Typography>}
      <Box sx={Styles.Children}>{children}</Box>
      <Box sx={Styles.Divider} />
      <CardFooter />
    </Box>
  );
};
